/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*::before,
*::after,
img {
  pointer-events: none;
}

body {
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

h1 {
  @include media-breakpoint-down(xs) {
    overflow: hidden;
    position: relative;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    height: 1px;
    width: 365px;
    background: white;
    @include media-breakpoint-down(xs) {
      left: 0px;
    }
  }
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

a,
img,
span,
button {
  display: inline-block;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.dflex {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.dflex-end {
  display: flex;
  align-items: end;
  vertical-align: middle;
}

.got {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham Narrow", sans-serif;
}

.h-50 {
  height: 50vh;
}

.h460 {
  height: 460px;
  @include media-breakpoint-down(md) {
    height: initial;
  }
}

h2.title-xl {
  font-style: italic;
}

.fw-l {
  font-weight: 300;
}

.fs-i {
  font-style: italic;
}
.upp {
  text-transform: uppercase;
}
.txt-l {
  text-align: left;
}
.pt-0-ip {
  padding-top: 0 !important;
}
.itc {
  font-family: "ITC Caslon 224 Std";
}
.img-wrapper {
  position: relative;
}
