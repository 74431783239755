.dispatcher {
    display: flex;
    width: 100%;
    height: 100%;

    position: fixed;
    inset: 0;
    z-index: 999;

    background-color: rgba(#000, .7);

    overflow: auto;

    &:not([data-opened=true]) {
        display: none;
    }

    &-inner {
        display: grid;

        margin: auto;

        background-color: #FFF;

        color: #1F2F3F;
    }

    &-image {
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 100%;

            position: absolute;
            inset: 0;

            object-fit: cover;
        }
    }

    &-form {
        padding: clamp(2rem, 6vw, 90px) clamp(1rem, 5vw, 80px);
    }

    &-title {
        font-family: $font-family-extra;
        font-size: clamp(1.75rem, 3.5vw, 2.5rem);
        line-height: 1.2;
    }

    &-item {
        margin-top: 1.5rem;
    }

    &-select {
        display: block;
        width: 100%;

        padding-block: 1rem;
        padding-right: 1.5rem;
        border-bottom: 1px solid;

        background-image: url(../img/icon/dispatcher-select-arrow.svg);
        background-repeat: no-repeat;
        background-size: 1.125rem auto;
        background-position: center right;

        font-size: 1.125rem;
        line-height: 1.2;
        color: inherit;
    }

    &-error {
        margin-top: .25rem;

        font-size: .875rem;
        color: #F00;
    }

    &-footer {
        position: relative;

        padding-right: 3rem;
        margin-top: 3rem;
        
        @at-root [data-loading=true] & {
            &::after {
                display: block;
                width: 2.5rem;
                height: 2.5rem;

                position: absolute;
                inset: 0 0 0 auto;

                margin-block: auto;

                background-image: url(../img/svg-loaders/puff-black.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                content: '';
            }
        }
    }

    &-submit {
        margin-left: 0;
    }

    @include media-breakpoint-up(md) {
        padding: clamp(1rem, 5vw, 80px);

        &-inner {
            grid-template-columns: 1fr 2fr;
        }
    }

    @include media-breakpoint-down(md) {
        &-inner {
            min-height: 100%;
            
            grid-template-columns: 100%;
        }

        &-image {
            aspect-ratio: 16/9;
        }
    }
}