/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select{
    -webkit-appearance: none;
    border-radius: 0;

    &:focus{
        outline: none;
    }
}

select{
    background-image: url('../img/icon/bottom-white.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-color: rgba(0,0,0,0);
    background-position: calc(100% - 10px) center;
    border: none;
    border-bottom: 1px solid $white;
    color: $white;
    font-family: $font-family-extra;
    font-size: 22px;
    width: max-content;
    padding-right: 40px;
    
    @include media-breakpoint-down(md) {
        width: auto;
    }
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap{
    .gform_fields{
        @extend .row;

        input, textarea{
            width: 100%;
            background-color: transparent;
        }
    }
}

/*
|
| Materialize form 
|-------------------
*/
.materialize-form{
    .materialize-form-group{
        position: relative;
        margin-bottom: 25px;

        label{
            position: absolute;
            top: 0;
            left: 15px;
        }
        input{
            border: none;
            background-color: rgba(0,0,0,0);
            border-bottom: 1px $white solid;
            color: $white;
            height: 25px;
        }

        &.gfield_error{
            label{
                top: -20px;
            }
        }
        
    }
}

/*
|
| Contact form 
|---------------
*/
.contact-form{

    li{
        margin-bottom: 40px;
        width: 80%;
    }

    label{
        position: absolute;
        color: $white;
        margin-top: -12px;
        text-transform: uppercase;
        letter-spacing: .1em;
    }

    textarea{
        border: none;
        background-color: rgba(0,0,0,0);
        border-bottom: 1px $white solid;
        color: $white;
        height: 120px;
        width: 100%;
        margin-bottom: 24px;
        text-transform: uppercase;
        letter-spacing: .1em;


        &.placeholder_disabled{
            color: $white;
        }
    }

    input{
        border: none;
        background-color: rgba(0,0,0,0);
        border-bottom: 1px $white solid;
        color: $white;
        height: 24px;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: .1em;
    }

    input[type=checkbox] {
        outline: 0;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        position: relative;

        display: inline-block;
        vertical-align: middle;

        width: 16px !important;
        height: 16px;

        border: 1px solid $white;

        margin-right: 10px;
        margin-bottom: 0;

        transition: .3s border;

        &:checked {
            border: 5px solid $white;
        }
    }

    #field_1_6{

        .gfield_label{
            display: none;
        }

        label{
            margin-top: 0px;
            margin-left: 12px;
        }
    }

    .gform_body{
        margin-bottom: 20px;
    }

    .gform_footer {
        position: relative;
        margin-left: -30px;

        &:before{
            content: '';
            position: absolute;
            width: 60px;
            height: 1px;
            background: $white;
            top: 50%;
            left: 0;
            z-index: 1;
        }
    }

    input[type="submit"]{
        padding: 20px 30px; 
        text-transform: uppercase;
        border: 1px solid $white;
        width: 280px;
        margin-left: 30px;
        text-align: center;
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: auto !important;
        transition: all .4s ease;

        &:hover{
            background: $white;
            color: $navy;
        }
    }

    .gform_confirmation_message{
        color: $white;
    }

    .validation_message{
        margin-top: 5px;
        color: #BB0B0B;
        font-size: 14px;
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}