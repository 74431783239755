/*
|--------------------
|      SINGLE
|--------------------
*/

.post-date {
    font-size: 12px;
    color: rgba($grey, 0.6);
    text-transform: uppercase;
    font-weight: 400;
}

.post-excerpt {
    font-size: 22px;
    color: rgba($very-dark-grey, 0.6);
    font-weight: 400;
}
@include media-breakpoint-up(lg) {
    .single-ctn {
        padding-left: 7px;
        padding-right: 7px;
    }
}
.single {
    h1:before {
        content: none;
    }
}
