/*
|--------------------
|      ESG
|--------------------
*/

#page-esg {
    h1 {
        position: relative;
        &:before {
            content: "";
            left: 0;
            width: 188px;
        }
    }
    .img-esg {
        position: relative;
        height: 600px;
    }
    .icon-container {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background-color: #dfe2e5;

        svg {
            path {
                fill: $navy;
            }
        }
    }
}

.img-half {
    top: 0;
    width: 50%;
}

.img-margin-up {
    // margin-top: -80px;

    @include media-breakpoint-down(lg) {
        margin-top: -40px;
    }
}

.col-r {
    right: 0;
}
