/*
|--------------------
|      CONTENT
|--------------------
*/
.t-c {
    text-align: center;
}
.max-w-none {
    max-width: none;
}

.ws-n {
    white-space: nowrap;
}
.section {
    position: relative;
}
.tt-u {
    text-transform: uppercase;
}
.rel {
    position: relative;
}

.f-white {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: $white;
        opacity: 0.7;
        transition: opacity 0.4s ease;
    }
}

.post-card {
    &:hover {
        .f-white {
            &:before {
                opacity: 0;
            }
        }
    }
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.o-blue {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: $navy;
        opacity: 0.64;
    }
}

.section-map {
    #map {
        position: relative;
        width: 100%;
        height: 80vh;
    }
}

.separator {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    z-index: 2;
    background: $navy;

    &.is-sm {
        margin-top: -40px;
        height: 80px;
    }

    &.is-md {
        margin-top: -80px;
        height: 160px;
    }

    &.is-lg {
        margin-top: -200px;
        height: 400px;
    }
    &-alt {
        background: white;
    }
}

.bg-overlay {
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: $navy;
        opacity: 0.8;
    }
}

.svg-white {
    svg {
        fill: $white;

        path,
        polygon,
        circle,
        text {
            fill: $white;
        }
    }
}

.back-btn-container {
    .hr-container {
        height: 1px;
        width: 4%;
        background: $white;
        left: 0;
        margin-top: 12px;
    }
}

.hr-l {
    position: relative;
    padding-left: 40px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 1px;
        background: $navy;
    }
}

.hr-b {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100px;
        background: $white;
    }
}

/*
|
| Cursor Follow
|--------------
*/
.cursor-container {
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    z-index: 400;
    transition: margin 0.4s ease;
    background: #fff;
    border-radius: 50%;
    height: 100px;
    font-size: 14px;
    width: 100px;
    transform: scale(0.4);
    opacity: 0;
    transition: transform 1.2s $easeSmooth, opacity 0.4s ease;
    pointer-events: none;

    .cursor-in {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 0;
        transition: opacity 0.4s ease;
    }
}

body {
    &.is-cursor {
        .cursor-container {
            transform: scale(1);
            opacity: 1;

            .cursor-in {
                opacity: 1;
            }
        }
    }
}

html {
    &.is-device {
        .cursor-container {
            display: none;
        }
    }
}

/*
|
| Banner
|---------
*/
.banner {
    @extend .bg-cover;
    position: relative;
    display: flex;
    align-items: end;
    width: 100%;
    padding: 120px 0 0 0;
    background-color: $navy;

    .item-content {
        width: 100%;
        padding: 60px 0;
        @include media-breakpoint-down(md) {
            .cms {
                margin-top: 20px;
            }
        }
        .cms {
            font-weight: 300;
            font-family: "Gotham Narrow";
        }
    }

    &:not(.banner-home) {
        .img-wrapper {
            width: 100%;
            right: 0;
            left: inherit;
        }
    }

    &.bg-before {
        background-color: rgba(0, 0, 0, 0);

        &:before {
            z-index: -1;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: $navy;
            height: 80vh;
            width: 100%;
        }

        // .bg-img{
        //     margin-top: 30vh;
        // }
    }
}

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
    .custom-card-link {
        display: flex;
        flex-direction: column;
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;

        &:after {
            content: "";
            display: block;
            padding-bottom: 70%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            transform: scale(1.01);
            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {
        display: flex;
        flex-direction: column;
        flex: 1 1 240px;

        .btn-container {
            align-self: flex-start;
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    /*
    * Hover state
    */
    &:hover {
        .card-img-container {
            .card-img {
                transform: scale(1.05);
            }
        }
    }
}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 2000;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}

/*
|
| CMS
|------
*/
.cms {
    span {
        display: inline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 30px;
    }

    h1 {
        @extend .title-xxl;
    }
    h2 {
        @extend .title-xl;
    }
    h3 {
        @extend .title-lg;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    strong {
        font-weight: 800;
    }

    p {
        margin-bottom: 2px;
    }

    &.cms-no-break {
        p {
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;

        width: 80%;

        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;

            background-image: url("../img/icon/quote-left.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;

            background-image: url("../img/icon/quote-right.svg");

            width: 50px;
            height: 50px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md) {
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(lg) {
        padding-top: $headerHeightSm;
    }
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: white;

        circle {
            stroke: white;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

.map-card {
    padding: 16px 16px;
}

.page-template-funds {
    h3 {
        position: relative;
        &:before {
            content: "";
            height: 1px;
            width: 100%;
            background-color: #fff;
            display: block;
            position: absolute;
            bottom: -4px;
        }
    }
}

#page-news-archive {
    .loaded-posts {
        color: white;
    }
}

.post-type-archive-projects,
.page-template-sustainability,
.page-template-funds,
.page-template-contact,
.page-template-dashboard,
.single,
.blog {
    #header {
        background: transparent;
    }
}

.post-type-archive-projects .banner,
.banner-img {
    /*     &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: $navy;
        opacity: 0.7;
        transition: opacity 0.4s ease;
    } */
}
