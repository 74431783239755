/*
|--------------------
|     PAGE HOME
|--------------------
*/

#page-home {
	display: block;
	.banner {
		.item-content {
			margin-top: -120px;
			color: white;
		}
		.img-container {
			color: white;
			a {
				color: white;
			}
		}

		.img-wrapper {
			/* 			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 100%;
				background: $white;
				opacity: 0.4;
				top: 0;
				left: 0;
			} */
		}
		/*
		.before-container {
			pointer-events: none;
			z-index: -1;

			.container {
				position: relative;

				.before-size {
					position: absolute;
					right: 0;
					top: 0;
					width: 1200px;

					&:before {
						content: "";
						position: absolute;
						height: 100%;
						margin-top: -170px;
						width: 59%;
						margin-right: -15%;
						right: 0;
						top: 0;
						background: $navy;
						z-index: -1;

						@include media-breakpoint-down(md) {
							width: 30%;
						}
					}
				}
			}

			// @media screen and (max-width: 1140px) {
			// 	width: 30%;
			// }
		}
*/
	}
	.section-about {
		.img-split {
			.img-container {
				margin-top: 0px;
			}
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background: $navy;
			width: 100%;
			height: 100%;
		}
	}

	.mobile-cursor {
		pointer-events: none;

		@include media-breakpoint-down(md) {
			pointer-events: initial;
		}
	}

	.split-container {
		display: flex;
		align-items: flex-end;
		min-height: 480px;

		.split-link {
			@include media-breakpoint-down(md) {
				height: 480px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.img-link {
			overflow: hidden;

			.bg-cover {
				transition: transform 2s $easeOutExpo;
			}

			&:hover {
				.bg-cover {
					transform: scale(1.1);
				}
			}
		}
	}

	.img-split {
		height: 400px;

		@include media-breakpoint-down(lg) {
			height: 400px;
		}

		@include media-breakpoint-down(md) {
			height: 480px;
		}

		@include media-breakpoint-down(sm) {
			height: 280px;

			.img-container {
				min-height: 280px;
			}
		}
	}

	.section.funds {
		.swiper-container {
			height: 400px;

			@include media-breakpoint-down(md) {
				height: 480px;
			}
		}
		.fund-item {
			position: relative;
			display: flex;
			align-items: center;
			color: white;
			height: 100%;
			width: 100%;
			overflow: hidden;
			text-align: center;

			@include media-breakpoint-up(md) {
				align-items: flex-end;
				text-align: left;
			}

			&::after {
				content: "";
				position: absolute;
				inset: 0;
				z-index: 2;
				background: $navy;
				opacity: 0.64;
			}
			img {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				pointer-events: none;
				transition: transform 2s $easeOutExpo;
			}

			&:hover {
				img {
					transform: scale(1.1);
				}
			}

			&-content {
				position: relative;
				z-index: 3;
				padding: 40px;
			}
		}
	}
}

.img-split {
	width: 50%;
	z-index: 20;

	&:not(.down-right) {
		top: 0;
	}

	&.down-right {
		bottom: 0;
	}

	&.col-l {
		left: 0;
	}

	&.col-r {
		right: 0;
	}

	.img-container {
		position: relative;
		overflow: hidden;
	}

	@include media-breakpoint-down(md) {
		height: 480px;
	}

	@include media-breakpoint-down(sm) {
		height: 280px;

		.img-container {
			min-height: 280px;
		}
	}
}

/* body {
	&.logged-in {
		#page-home {
			.banner {
				.before-container {
					.container {
						.before-size {
							&:before {
								width: 72%;
							}
						}
					}
				}
			}
		}
	}
	
} */
