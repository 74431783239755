/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/

/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
$font-size-xs: 12px;
$screen-lg-font-size-xs: $font-size-xs;
$screen-md-font-size-xs: $font-size-xs;
$screen-sm-font-size-xs: $font-size-xs;
$screen-xs-font-size-xs: $font-size-xs;

$font-size-sm: 16px;
$screen-lg-font-size-sm: $font-size-sm;
$screen-md-font-size-sm: $font-size-sm;
$screen-sm-font-size-sm: $font-size-sm;
$screen-xs-font-size-sm: $font-size-sm;

$font-size-md: 20px;
$screen-lg-font-size-md: 18px;
$screen-md-font-size-md: 16px;
$screen-sm-font-size-md: 16px;
$screen-xs-font-size-md: 16px;

$font-size-lg: 28px;
$screen-lg-font-size-lg: $font-size-lg;
$screen-md-font-size-lg: $font-size-lg;
$screen-sm-font-size-lg: $font-size-lg;
$screen-xs-font-size-lg: $font-size-lg;

$font-size-xl: 36px;
$screen-lg-font-size-xl: 36px;
$screen-md-font-size-xl: 32px;
$screen-sm-font-size-xl: 28px;
$screen-xs-font-size-xl: 24px;

$font-size-xxl: 50px;
$screen-lg-font-size-xxl: $font-size-xxl;
$screen-md-font-size-xxl: $font-size-xxl;
$screen-sm-font-size-xxl: $font-size-xxl;
$screen-xs-font-size-xxl: $font-size-xxl;

/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
$font-size-title-xs: 16px;
$screen-lg-font-size-title-xs: 16px;
$screen-md-font-size-title-xs: 16px;
$screen-sm-font-size-title-xs: 14px;
$screen-xs-font-size-title-xs: 14px;

$font-size-title-sm: 18px;
$screen-lg-font-size-title-sm: 18px;
$screen-md-font-size-title-sm: 18px;
$screen-sm-font-size-title-sm: 16px;
$screen-xs-font-size-title-sm: 16px;

$font-size-title-md: 26px;
$screen-lg-font-size-title-md: 24px;
$screen-md-font-size-title-md: 20px;
$screen-sm-font-size-title-md: 20px;
$screen-xs-font-size-title-md: 20px;

$font-size-title-lg: 32px;
$screen-lg-font-size-title-lg: 32px;
$screen-md-font-size-title-lg: 32px;
$screen-sm-font-size-title-lg: 32px;
$screen-xs-font-size-title-lg: 32px;

$font-size-title-xl: 36px;
$screen-lg-font-size-title-xl: 36px;
$screen-md-font-size-title-xl: 36px;
$screen-sm-font-size-title-xl: 48px;
$screen-xs-font-size-title-xl: 40px;

$font-size-title-xxl: 72px;
$screen-lg-font-size-title-xxl: 72px;
$screen-md-font-size-title-xxl: 60px;
$screen-sm-font-size-title-xxl: 52px;
$screen-xs-font-size-title-xxl: 40px;

/*
|
| FONT-FAMILIES
|----------------
|
*/
$font-family-default: "Gotham Narrow Book", sans-serif;
$font-family-custom: "Gotham Narrow", sans-serif;
$font-family-extra: "ITC Caslon 224 Std", sans-serif;

/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
$font-families: (
  "default": $font-family-default,
  "custom": $font-family-custom,
  "extra": $font-family-extra,
);

/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
$letter-spacing-xs: 1px;
$letter-spacing-sm: 2px;
$letter-spacing-md: 3px;
$letter-spacing-lg: 4px;
$letter-spacing-xl: 5px;
$letter-spacing-xxl: 6px;

/*
|
| LINE-HEIGHT
|--------------
|
*/
$line-height-xs: 1;
$line-height-sm: 1.2;
$line-height-md: 1.5;
$line-height-lg: 1.6;
$line-height-xl: 1.8;
$line-height-xxl: 2;

/*
|
| Default font settings
|------------------------
|
*/
$default-font-size: $font-size-sm;
$default-color: $text-color;
$default-line-height: $line-height-xl;
$default-font-weight: 400;

/*
|
| Default titles settings
|--------------------------
|
*/
$default-titles: (
  color: $gold,
  font-family: $font-family-extra,
  font-weight: 400,
  line-height: 1.2,
);
