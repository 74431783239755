/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #1a3041;
$very-dark-grey: #1a3041;
$dark-grey: #1a3041;
$gold: #d3b68c;
$grey: #5e6f7c;
$light-grey: #dedede;
$very-light-grey: #fafafa;
$white: #ffffff;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

$navy: #1a3041;
$dark-navy: #0a1720;

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $navy;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
$colors: (
  //Required colors
  "navy": $navy,
  "dark-navy": $dark-navy,
  "black": $black,
  "gold": $gold,
  "very-dark-grey": $very-dark-grey,
  "dark-grey": $dark-grey,
  "grey": $grey,
  "light-grey": $light-grey,
  "very-light-grey": $very-light-grey,
  "white": $white
);
