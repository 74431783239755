/*
|--------------------
|     HELPERS
|--------------------
*/
.desktop-only {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.mobile-only {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

.img-full{
    height: 480px;
}

.c-c{
  column-count: 2;

  @include media-breakpoint-down(md) {
    column-count: 1;
    }
}

.z-up{
    z-index: 12;
}

.t-0{
    top: 0;
}

.l-0{
    left: 0;
}

.jc-sb{
	justify-content: space-between;
}

.jc-end{
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}

.jc-c{
	justify-content: center;
}

.ai-b{
	align-items: baseline;
}

.ai-c{
	align-items: center;
}

.fd-c{
	flex-direction: column;
}

.tt-n{
    text-transform: none !important;
}


@include media-breakpoint-down(lg) {

    .lg-fd-c{
        flex-direction: column;
    }
}