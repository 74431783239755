/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
    font-family: "content";
    src: url("../fonts/roboto/regular.eot");
    src: url("../fonts/roboto/regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/roboto/regular.woff2") format("woff2"), url("../fonts/roboto/regular.woff") format("woff"),
        url("../fonts/roboto/regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "title";
    src: url("../fonts/jomolhari/title.eot");
    src: url("../fonts/jomolhari/title.eot?#iefix") format("embedded-opentype"),
        url("../fonts/jomolhari/title.woff2") format("woff2"), url("../fonts/jomolhari/title.woff") format("woff"),
        url("../fonts/jomolhari/title.ttf") format("truetype"), url("../fonts/jomolhari/title.svg#title") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-Book.eot");
    src: url("../fonts/caslon/Caslon224Std-Book.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-Book.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-Book.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-Book.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-Book.svg#Caslon224Std-Book") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-BoldItalic.eot");
    src: url("../fonts/caslon/Caslon224Std-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-BoldItalic.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-BoldItalic.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-BoldItalic.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-BoldItalic.svg#Caslon224Std-BoldItalic") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-Black.eot");
    src: url("../fonts/caslon/Caslon224Std-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-Black.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-Black.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-Black.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-Black.svg#Caslon224Std-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-BlackItalic.eot");
    src: url("../fonts/caslon/Caslon224Std-BlackItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-BlackItalic.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-BlackItalic.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-BlackItalic.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-BlackItalic.svg#Caslon224Std-BlackItalic") format("svg");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-MediumItalic.eot");
    src: url("../fonts/caslon/Caslon224Std-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-MediumItalic.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-MediumItalic.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-MediumItalic.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-MediumItalic.svg#Caslon224Std-MediumItalic") format("svg");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-Bold.eot");
    src: url("../fonts/caslon/Caslon224Std-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-Bold.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-Bold.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-Bold.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-Bold.svg#Caslon224Std-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-BookItalic.eot");
    src: url("../fonts/caslon/Caslon224Std-BookItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-BookItalic.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-BookItalic.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-BookItalic.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-BookItalic.svg#Caslon224Std-BookItalic") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "ITC Caslon 224 Std";
    src: url("../fonts/caslon/Caslon224Std-Medium.eot");
    src: url("../fonts/caslon/Caslon224Std-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/caslon/Caslon224Std-Medium.woff2") format("woff2"),
        url("../fonts/caslon/Caslon224Std-Medium.woff") format("woff"),
        url("../fonts/caslon/Caslon224Std-Medium.ttf") format("truetype"),
        url("../fonts/caslon/Caslon224Std-Medium.svg#Caslon224Std-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow Book";
    src: url("../fonts/gotham/GothamNarrow-Book.eot");
    src: url("../fonts/gotham/GothamNarrow-Book.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-Book.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-Book.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-Book.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-Book.svg#GothamNarrow-Book") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-Bold.eot");
    src: url("../fonts/gotham/GothamNarrow-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-Bold.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-Bold.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-Bold.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-Bold.svg#GothamNarrow-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-BoldItalic.eot");
    src: url("../fonts/gotham/GothamNarrow-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-BoldItalic.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-BoldItalic.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-BoldItalic.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-BoldItalic.svg#GothamNarrow-BoldItalic") format("svg");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow Book";
    src: url("../fonts/gotham/GothamNarrow-BookItalic.eot");
    src: url("../fonts/gotham/GothamNarrow-BookItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-BookItalic.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-BookItalic.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-BookItalic.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-BookItalic.svg#GothamNarrow-BookItalic") format("svg");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-Light.eot");
    src: url("../fonts/gotham/GothamNarrow-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-Light.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-Light.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-Light.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-Light.svg#GothamNarrow-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-LightItalic.eot");
    src: url("../fonts/gotham/GothamNarrow-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-LightItalic.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-LightItalic.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-LightItalic.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-LightItalic.svg#GothamNarrow-LightItalic") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-Medium.eot");
    src: url("../fonts/gotham/GothamNarrow-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-Medium.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-Medium.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-Medium.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-Medium.svg#GothamNarrow-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham Narrow";
    src: url("../fonts/gotham/GothamNarrow-MediumItalic.eot");
    src: url("../fonts/gotham/GothamNarrow-MediumItalic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/gotham/GothamNarrow-MediumItalic.woff2") format("woff2"),
        url("../fonts/gotham/GothamNarrow-MediumItalic.woff") format("woff"),
        url("../fonts/gotham/GothamNarrow-MediumItalic.ttf") format("truetype"),
        url("../fonts/gotham/GothamNarrow-MediumItalic.svg#GothamNarrow-MediumItalic") format("svg");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
