/*
|--------------------
|      FOOTER
|--------------------
*/

.item-footer {
	width: 80%;
	a,
	span {
		color: #ffffff;
		font-weight: 400;
	}
}

.item-logo {
	img {
		height: 90px;
	}
}

.credit-link {
	opacity: 0.5;
	transition: opacity 0.8s ease;

	span {
		text-transform: none;
		font-style: italic;
	}

	&:hover {
		opacity: 1;
	}
}

#ot-sdk-btn {
	border: none !important;
	color: white !important;
	text-transform: uppercase !important;
	font-size: 16px !important;
	padding: 0px !important;
	margin: 0px !important;

	&:hover {
		background: none !important;
	}
}
