/*
|--------------------
|      DASHBOARD PAGE
|--------------------
*/

#page-dashboard {
    .name-login {
        display: none;
        font-style: italic;
        @media (max-width: 1276px) {
            display: block;
        }
    }

    .name-login-alt {
        position: absolute;
        top: -55px;
        right: 0;
        text-align: right;
        color: white;
        text-transform: initial;
        display: inline-table;
        font-size: 20px;
        font-style: italic;
        @media (max-width: 1276px) {
            display: none;
        }
    }
    h1 {
        position: relative;
        display: inline-block;
        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 365px;
            background: white;
            @include media-breakpoint-down(sm) {
                width: initial;
            }
        }
    }
}

.um-form {
    .um-field-label {
        label {
            color: $white !important;
            text-transform: uppercase !important;
            font-weight: normal !important;
        }
    }
    .um-field-area input,
    .um .um-form input[type="password"] {
        color: $white !important;
        background: none !important;
        border: none !important;
        border-bottom: 1px solid $white !important;
    }

    .um-field-checkbox-option {
        color: $white !important;
    }

    .um-col-alt-b {
        a {
            color: $white !important;
            opacity: 1 !important;
            text-decoration: underline !important;
        }
    }

    .um-col-alt {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .um-center {
        position: relative;
        width: fit-content !important;

        &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 1px;
            background: $white;
            top: 50%;
            left: 0;
            z-index: 1;
        }
    }

    input[type="submit"] {
        padding: 20px 30px !important;
        text-transform: uppercase !important;
        border: 1px solid $white !important;
        width: 280px !important;
        background: rgba(0, 0, 0, 0) !important;
        border-radius: 0px !important;
        margin-left: 30px !important;
        text-align: center !important;
        position: relative !important;
        display: inline-block !important;
        cursor: pointer !important;
        height: auto !important;
        transition: all 0.4s ease !important;

        &:hover {
            background: $white !important;
            color: $navy;
        }
    }
}

// Page dashboard

.custom-options {
    &.is-scrollable {
        max-height: 400px;
        overflow: auto;
        width: 400px;

        @include media-breakpoint-down(md) {
            width: auto;
        }
    }
}

.banner-dash {
    min-height: fit-content;
}

.share-content {
    display: none;
    transition: opacity 0.4s ease;

    &.is-active {
        display: block;
    }

    .row-custom {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 24px);
        margin-left: -12px;
        justify-content: space-between;

        .col-custom {
            flex: 1 1 0px;
            margin: 12px;
            overflow: hidden;
            @include media-breakpoint-up(lg) {
                &:first-child {
                    .col-11 {
                        padding-right: 0px;
                        padding-left: 0px;
                    }
                }
            }
            .bg-navy {
                height: 280px;
                align-items: center;
                @include media-breakpoint-down(md) {
                    height: initial;
                }
            }
            .title-db {
                height: 67px;
                display: table-cell;
                vertical-align: bottom;
                position: relative;
                &:before {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background-color: #fff;
                    display: block;
                    position: absolute;
                    bottom: -7px;
                }
                h3 {
                    vertical-align: bottom;
                    position: absolute;
                    bottom: -3px;

                    @include media-breakpoint-down(lg) {
                        font-size: 23px !important;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            justify-content: unset;

            .col-custom {
                flex: 0 0 20em;
            }
        }

        @include media-breakpoint-down(sm) {
            max-width: 540px;

            .col-custom {
                flex: 0 0 100%;
                padding-right: 24px;
            }
        }
    }
}

.page-template-dashboard {
    .sep {
        margin-left: 15px;
    }
    .col-1-desk {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.report-single {
    display: none;
    transition: opacity 0.4s ease;

    .img-container {
        height: 300px;
        display: flex;
    }

    &.is-active {
        display: block;
    }
}

.report-select__trigger {
    padding: 0px !important;
    white-space: nowrap;
}

.launch-date-wrapper {
    background-color: $very-dark-grey;
    transform: translateY(-100%);
}

.graphic-single {
    canvas {
    }
}

.report-option {
    @include media-breakpoint-down(md) {
        font-size: 12px;
    }
}

.bloc-filters {
    float: left;
    @include media-breakpoint-down(md) {
        float: initial;
        min-width: initial;
    }

    .custom-select__trigger {
        padding: 0;
        span {
            padding-right: 20px;
            color: white;
        }
    }
    &.bloc-filters2 {
    }
}
