/*
|--------------------
|      NEWS
|--------------------
*/
#page-news-archive {
  .bloc-filters {
    float: right;
    width: 410px;
    @include media-breakpoint-down(md) {
      float: left;
      width: 100%;
    }
  }
}

.select-container {
  // margin: 20px;
  max-width: 600px;
  font-family: $font-family-extra;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
}

.custom-select {
  display: flex;
  flex-direction: column;
  border: none;
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 300;
  // color: $white;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  z-index: 1;

  &.white {
    border-bottom: 1px solid $white;
  }

  &.blue {
    border-bottom: 1px solid $navy;
  }
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0;
  background: $navy;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
}

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0 16px 0 16px;
  font-size: 18px;
  font-weight: 300;
  color: $white;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  background-color: $grey;
}

.custom-option.selected {
  color: $navy;
  background-color: $white;
}

.arrow {
  position: relative;
  height: 15px;
  width: 15px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.5s;
  }

  &.blue {
    &:before,
    &:after {
      background-color: $navy;
    }
  }

  &.white {
    &:before,
    &:after {
      background-color: $white;
    }
  }
}

.arrow::before {
  left: -5px;
  transform: rotate(-45deg);
}

.arrow::after {
  left: 5px;
  transform: rotate(45deg);
}

.open .arrow::before {
  left: -5px;
  transform: rotate(45deg);
}

.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg);
}

.load-more-container {
  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  .loader-container {
    display: none;
  }
}
