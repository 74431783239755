/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/

/*
|
| Bg img default
|----------------------
|
*/

$bg-img-default : (
    background-size: cover,
    background-position: center center,
    background-repeat: no-repeat
);

/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
$bg-imgs: (
    'square'       : 100%,
    'almost-square': 85%,
    'landscape'    : 70%,
    'landscape-sm' : 60%,
    'portrait'     : 130%,
);
