/*
|--------------------
|       Contact
|--------------------
*/

.separator-col {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 100%;
		background: $white;
	}
}

.captcha-label {
	label {
		display: none;
	}
}
