/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container, #mobile-menu .item-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 90vw; } }
  @media (min-width: 1440px) {
    .container, #header .header-container, #mobile-menu .item-container {
      max-width: 90vw; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom, .banner {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1439.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1440px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1439.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1440px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1439.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1440px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1439.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1440px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1439.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1440px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1439.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1440px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1439.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1439.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 20px !important; }
  @media (max-width: 1439.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 16px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1439.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1439.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 32px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 24px !important; } }

.fs-xxl {
  font-size: 50px !important; }
  @media (max-width: 1439.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 50px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 50px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1439.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 14px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 14px; } }

.title-sm, .cms h5 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1439.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 16px; } }

.title-md, .cms h4 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 26px; }
  @media (max-width: 1439.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 20px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 20px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 20px; } }

.title-lg, .cms h3 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 32px; }
  @media (max-width: 1439.98px) {
    .title-lg, .cms h3 {
      font-size: 32px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 32px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 32px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 32px; } }

.title-xl, .cms h2 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 36px; }
  @media (max-width: 1439.98px) {
    .title-xl, .cms h2 {
      font-size: 36px; } }
  @media (max-width: 991.98px) {
    .title-xl, .cms h2 {
      font-size: 36px; } }
  @media (max-width: 767.98px) {
    .title-xl, .cms h2 {
      font-size: 48px; } }
  @media (max-width: 575.98px) {
    .title-xl, .cms h2 {
      font-size: 40px; } }

.title-xxl, .cms h1 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 72px; }
  @media (max-width: 1439.98px) {
    .title-xxl, .cms h1 {
      font-size: 72px; } }
  @media (max-width: 991.98px) {
    .title-xxl, .cms h1 {
      font-size: 60px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1 {
      font-size: 52px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1 {
      font-size: 40px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Gotham Narrow Book", sans-serif ; }

.ff-custom {
  font-family: "Gotham Narrow", sans-serif ; }

.ff-extra {
  font-family: "ITC Caslon 224 Std", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-navy {
  color: #1a3041 ; }

.c-dark-navy {
  color: #0a1720 ; }

.c-black {
  color: #1a3041 ; }

.c-gold {
  color: #d3b68c ; }

.c-very-dark-grey {
  color: #1a3041 ; }

.c-dark-grey {
  color: #1a3041 ; }

.c-grey {
  color: #5e6f7c ; }

.c-light-grey {
  color: #dedede ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #ffffff ; }

/*------- LINKS -------*/
.link-white {
  color: #ffffff; }
  .link-white:hover {
    color: #dedede; }

.links-white a {
  color: #ffffff; }
  .links-white a:hover {
    color: #dedede; }

.link-black {
  color: #1a3041; }
  .link-black:hover {
    color: #5e6f7c; }

.links-black a {
  color: #1a3041; }
  .links-black a:hover {
    color: #5e6f7c; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "Gotham Narrow Book", sans-serif;
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  background: #ffffff;
  color: #1a3041;
  border: 1px solid #dedede;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #1a3041; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #1a3041;
  border-color: #1a3041;
  color: #ffffff; }
  .btn-black:hover {
    color: #ffffff; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1a3041; }
  .btn-white:hover {
    color: #1a3041; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-navy {
  background-color: #1a3041 ; }

.bg-dark-navy {
  background-color: #0a1720 ; }

.bg-black {
  background-color: #1a3041 ; }

.bg-gold {
  background-color: #d3b68c ; }

.bg-very-dark-grey {
  background-color: #1a3041 ; }

.bg-dark-grey {
  background-color: #1a3041 ; }

.bg-grey {
  background-color: #5e6f7c ; }

.bg-light-grey {
  background-color: #dedede ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #ffffff ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1439.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1440px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1439.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1440px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1439.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1440px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1439.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1440px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1439.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1440px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1439.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1440px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1439.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1440px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1439.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1440px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1439.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1440px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1439.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1440px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 60px !important;
  padding-bottom: 60px !important; }
  @media (max-width: 1439.98px) {
    .section {
      padding-top: 60px !important;
      padding-bottom: 60px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 60px !important;
      padding-bottom: 60px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 60px !important;
      padding-bottom: 60px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 60px !important;
      padding-bottom: 60px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 20px !important; }

.mt-md {
  margin-top: 20px !important; }

.mb-md {
  margin-bottom: 20px !important; }

.my-md {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.ml-md {
  margin-left: 20px !important; }

.mr-md {
  margin-right: 20px !important; }

.mx-md {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.p-md {
  padding: 20px !important; }

.pt-md {
  padding-top: 20px !important; }

.pb-md {
  padding-bottom: 20px !important; }

.py-md {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.pl-md {
  padding-left: 20px !important; }

.pr-md {
  padding-right: 20px !important; }

.px-md {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.m-lg {
  margin: 30px !important; }

.mt-lg {
  margin-top: 30px !important; }

.mb-lg {
  margin-bottom: 30px !important; }

.my-lg {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-lg {
  margin-left: 30px !important; }

.mr-lg {
  margin-right: 30px !important; }

.mx-lg {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-lg {
  padding: 30px !important; }

.pt-lg {
  padding-top: 30px !important; }

.pb-lg {
  padding-bottom: 30px !important; }

.py-lg {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-lg {
  padding-left: 30px !important; }

.pr-lg {
  padding-right: 30px !important; }

.px-lg {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-xl {
  margin: 40px !important; }

.mt-xl {
  margin-top: 40px !important; }

.mb-xl {
  margin-bottom: 40px !important; }

.my-xl {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.ml-xl {
  margin-left: 40px !important; }

.mr-xl {
  margin-right: 40px !important; }

.mx-xl {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.p-xl {
  padding: 40px !important; }

.pt-xl {
  padding-top: 40px !important; }

.pb-xl {
  padding-bottom: 40px !important; }

.py-xl {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.pl-xl {
  padding-left: 40px !important; }

.pr-xl {
  padding-right: 40px !important; }

.px-xl {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.m-xxl {
  margin: 80px !important; }

.mt-xxl {
  margin-top: 80px !important; }

.mb-xxl {
  margin-bottom: 80px !important; }

.my-xxl {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.ml-xxl {
  margin-left: 80px !important; }

.mr-xxl {
  margin-right: 80px !important; }

.mx-xxl {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.p-xxl {
  padding: 80px !important; }

.pt-xxl {
  padding-top: 80px !important; }

.pb-xxl {
  padding-bottom: 80px !important; }

.py-xxl {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pl-xxl {
  padding-left: 80px !important; }

.pr-xxl {
  padding-right: 80px !important; }

.px-xxl {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 20px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 20px !important; }

.mt-md-child > * {
  margin-top: 20px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 20px !important; }

.mb-md-child > * {
  margin-bottom: 20px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 20px !important; }

.my-md-child > * {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.ml-md-child > * {
  margin-left: 20px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 20px !important; }

.mr-md-child > * {
  margin-right: 20px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 20px !important; }

.mx-md-child > * {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.p-md-child > * {
  padding: 20px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 20px !important; }

.pt-md-child > * {
  padding-top: 20px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 20px !important; }

.pb-md-child > * {
  padding-bottom: 20px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 20px !important; }

.py-md-child > * {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.pl-md-child > * {
  padding-left: 20px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 20px !important; }

.pr-md-child > * {
  padding-right: 20px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 20px !important; }

.px-md-child > * {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.m-lg-child > * {
  margin: 30px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-lg-child > * {
  margin-top: 30px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-lg-child > * {
  margin-bottom: 30px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-lg-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-lg-child > * {
  margin-left: 30px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-lg-child > * {
  margin-right: 30px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-lg-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-lg-child > * {
  padding: 30px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-lg-child > * {
  padding-top: 30px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-lg-child > * {
  padding-bottom: 30px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-lg-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-lg-child > * {
  padding-left: 30px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-lg-child > * {
  padding-right: 30px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-lg-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-xl-child > * {
  margin: 40px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 40px !important; }

.mt-xl-child > * {
  margin-top: 40px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 40px !important; }

.mb-xl-child > * {
  margin-bottom: 40px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 40px !important; }

.my-xl-child > * {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.ml-xl-child > * {
  margin-left: 40px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 40px !important; }

.mr-xl-child > * {
  margin-right: 40px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 40px !important; }

.mx-xl-child > * {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.p-xl-child > * {
  padding: 40px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 40px !important; }

.pt-xl-child > * {
  padding-top: 40px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 40px !important; }

.pb-xl-child > * {
  padding-bottom: 40px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 40px !important; }

.py-xl-child > * {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.pl-xl-child > * {
  padding-left: 40px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 40px !important; }

.pr-xl-child > * {
  padding-right: 40px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 40px !important; }

.px-xl-child > * {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.m-xxl-child > * {
  margin: 80px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 80px !important; }

.mt-xxl-child > * {
  margin-top: 80px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important; }

.mb-xxl-child > * {
  margin-bottom: 80px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 80px !important; }

.my-xxl-child > * {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.ml-xxl-child > * {
  margin-left: 80px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important; }

.mr-xxl-child > * {
  margin-right: 80px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 80px !important; }

.mx-xxl-child > * {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.p-xxl-child > * {
  padding: 80px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 80px !important; }

.pt-xxl-child > * {
  padding-top: 80px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important; }

.pb-xxl-child > * {
  padding-bottom: 80px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 80px !important; }

.py-xxl-child > * {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pl-xxl-child > * {
  padding-left: 80px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important; }

.pr-xxl-child > * {
  padding-right: 80px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 80px !important; }

.px-xxl-child > * {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important;
  padding-right: 80px !important; }

@media (max-width: 1439.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-md {
    margin: 20px !important; }
  .lg-down-m-md-child > * {
    margin: 20px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-md {
    margin-top: 20px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 20px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-md {
    margin-bottom: 20px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 20px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .lg-down-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-md {
    margin-left: 20px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 20px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-md {
    margin-right: 20px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 20px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-md {
    padding: 20px !important; }
  .lg-down-p-md-child > * {
    padding: 20px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-md {
    padding-top: 20px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 20px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-md {
    padding-bottom: 20px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 20px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .lg-down-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-md {
    padding-left: 20px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 20px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-md {
    padding-right: 20px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 20px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .lg-down-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-lg {
    margin: 30px !important; }
  .lg-down-m-lg-child > * {
    margin: 30px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-lg {
    margin-top: 30px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-lg {
    margin-bottom: 30px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-lg {
    margin-left: 30px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-lg {
    margin-right: 30px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-lg {
    padding: 30px !important; }
  .lg-down-p-lg-child > * {
    padding: 30px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-lg {
    padding-top: 30px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-lg {
    padding-bottom: 30px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-lg {
    padding-left: 30px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-lg {
    padding-right: 30px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-xl {
    margin: 40px !important; }
  .lg-down-m-xl-child > * {
    margin: 40px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-xl {
    margin-top: 40px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 40px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-xl {
    margin-bottom: 40px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-xl {
    margin-left: 40px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 40px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-xl {
    margin-right: 40px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 40px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-xl {
    padding: 40px !important; }
  .lg-down-p-xl-child > * {
    padding: 40px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-xl {
    padding-top: 40px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 40px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-xl {
    padding-bottom: 40px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-xl {
    padding-left: 40px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 40px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-xl {
    padding-right: 40px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 40px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-m-xxl {
    margin: 80px !important; }
  .lg-down-m-xxl-child > * {
    margin: 80px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mt-xxl {
    margin-top: 80px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 80px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 80px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-ml-xxl {
    margin-left: 80px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 80px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mr-xxl {
    margin-right: 80px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 80px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-p-xxl {
    padding: 80px !important; }
  .lg-down-p-xxl-child > * {
    padding: 80px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pt-xxl {
    padding-top: 80px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 80px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 80px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pl-xxl {
    padding-left: 80px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 80px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-pr-xxl {
    padding-right: 80px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 80px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 1439.98px) {
  .lg-down-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 20px !important; }
  .md-down-m-md-child > * {
    margin: 20px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 20px !important; }
  .md-down-mt-md-child > * {
    margin-top: 20px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 20px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 20px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .md-down-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 20px !important; }
  .md-down-ml-md-child > * {
    margin-left: 20px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 20px !important; }
  .md-down-mr-md-child > * {
    margin-right: 20px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .md-down-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 20px !important; }
  .md-down-p-md-child > * {
    padding: 20px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 20px !important; }
  .md-down-pt-md-child > * {
    padding-top: 20px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 20px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 20px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .md-down-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 20px !important; }
  .md-down-pl-md-child > * {
    padding-left: 20px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 20px !important; }
  .md-down-pr-md-child > * {
    padding-right: 20px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .md-down-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 30px !important; }
  .md-down-m-lg-child > * {
    margin: 30px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 30px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 30px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 30px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 30px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 30px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 30px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 30px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 30px !important; }
  .md-down-p-lg-child > * {
    padding: 30px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 30px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 30px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 30px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 30px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 30px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 30px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 30px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 40px !important; }
  .md-down-m-xl-child > * {
    margin: 40px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 40px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 40px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 40px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .md-down-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 40px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 40px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 40px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 40px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 40px !important; }
  .md-down-p-xl-child > * {
    padding: 40px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 40px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 40px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 40px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .md-down-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 40px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 40px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 40px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 40px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .md-down-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 80px !important; }
  .md-down-m-xxl-child > * {
    margin: 80px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 80px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 80px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 80px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 80px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 80px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 80px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 80px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 80px !important; }
  .md-down-p-xxl-child > * {
    padding: 80px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 80px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 80px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 80px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 80px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 80px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 80px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 80px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 20px !important; }
  .sm-down-m-md-child > * {
    margin: 20px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 20px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 20px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 20px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 20px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .sm-down-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 20px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 20px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 20px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 20px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 20px !important; }
  .sm-down-p-md-child > * {
    padding: 20px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 20px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 20px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 20px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 20px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .sm-down-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 20px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 20px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 20px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 20px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .sm-down-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 30px !important; }
  .sm-down-m-lg-child > * {
    margin: 30px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 30px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 30px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 30px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 30px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 30px !important; }
  .sm-down-p-lg-child > * {
    padding: 30px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 30px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 30px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 30px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 30px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 40px !important; }
  .sm-down-m-xl-child > * {
    margin: 40px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 40px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 40px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 40px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 40px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 40px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 40px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 40px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 40px !important; }
  .sm-down-p-xl-child > * {
    padding: 40px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 40px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 40px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 40px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 40px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 40px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 40px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 40px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 80px !important; }
  .sm-down-m-xxl-child > * {
    margin: 80px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 80px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 80px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 80px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 80px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 80px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 80px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 80px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 80px !important; }
  .sm-down-p-xxl-child > * {
    padding: 80px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 80px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 80px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 80px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 80px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 80px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 80px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 80px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 20px !important; }
  .xs-down-m-md-child > * {
    margin: 20px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 20px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 20px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 20px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 20px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .xs-down-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 20px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 20px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 20px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 20px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 20px !important; }
  .xs-down-p-md-child > * {
    padding: 20px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 20px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 20px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 20px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 20px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .xs-down-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 20px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 20px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 20px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 20px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .xs-down-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 30px !important; }
  .xs-down-m-lg-child > * {
    margin: 30px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 30px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 30px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 30px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 30px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 30px !important; }
  .xs-down-p-lg-child > * {
    padding: 30px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 30px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 30px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 30px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 30px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 40px !important; }
  .xs-down-m-xl-child > * {
    margin: 40px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 40px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 40px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 40px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 40px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 40px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 40px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 40px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 40px !important; }
  .xs-down-p-xl-child > * {
    padding: 40px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 40px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 40px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 40px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 40px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 40px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 40px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 40px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 80px !important; }
  .xs-down-m-xxl-child > * {
    margin: 80px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 80px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 80px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 80px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 80px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 80px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 80px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 80px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 80px !important; }
  .xs-down-p-xxl-child > * {
    padding: 80px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 80px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 80px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 80px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 80px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 80px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 80px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 80px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 20px !important; }

.xs-up-m-md-child > * {
  margin: 20px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 20px !important; }

.xs-up-mt-md {
  margin-top: 20px !important; }

.xs-up-mt-md-child > * {
  margin-top: 20px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 20px !important; }

.xs-up-mb-md {
  margin-bottom: 20px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 20px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 20px !important; }

.xs-up-my-md {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.xs-up-my-md-child > * {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.xs-up-ml-md {
  margin-left: 20px !important; }

.xs-up-ml-md-child > * {
  margin-left: 20px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 20px !important; }

.xs-up-mr-md {
  margin-right: 20px !important; }

.xs-up-mr-md-child > * {
  margin-right: 20px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 20px !important; }

.xs-up-mx-md {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.xs-up-mx-md-child > * {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.xs-up-p-md {
  padding: 20px !important; }

.xs-up-p-md-child > * {
  padding: 20px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 20px !important; }

.xs-up-pt-md {
  padding-top: 20px !important; }

.xs-up-pt-md-child > * {
  padding-top: 20px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 20px !important; }

.xs-up-pb-md {
  padding-bottom: 20px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 20px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 20px !important; }

.xs-up-py-md {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.xs-up-py-md-child > * {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.xs-up-pl-md {
  padding-left: 20px !important; }

.xs-up-pl-md-child > * {
  padding-left: 20px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 20px !important; }

.xs-up-pr-md {
  padding-right: 20px !important; }

.xs-up-pr-md-child > * {
  padding-right: 20px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 20px !important; }

.xs-up-px-md {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.xs-up-px-md-child > * {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.xs-up-m-lg {
  margin: 30px !important; }

.xs-up-m-lg-child > * {
  margin: 30px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-lg {
  margin-top: 30px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 30px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-lg {
  margin-bottom: 30px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-lg {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-lg-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-lg {
  margin-left: 30px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 30px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-lg {
  margin-right: 30px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 30px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-lg {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-lg {
  padding: 30px !important; }

.xs-up-p-lg-child > * {
  padding: 30px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-lg {
  padding-top: 30px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 30px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-lg {
  padding-bottom: 30px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-lg {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-lg-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-lg {
  padding-left: 30px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 30px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-lg {
  padding-right: 30px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 30px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-lg {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-lg-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-xl {
  margin: 40px !important; }

.xs-up-m-xl-child > * {
  margin: 40px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 40px !important; }

.xs-up-mt-xl {
  margin-top: 40px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 40px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 40px !important; }

.xs-up-mb-xl {
  margin-bottom: 40px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 40px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 40px !important; }

.xs-up-my-xl {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.xs-up-my-xl-child > * {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.xs-up-ml-xl {
  margin-left: 40px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 40px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 40px !important; }

.xs-up-mr-xl {
  margin-right: 40px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 40px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 40px !important; }

.xs-up-mx-xl {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.xs-up-p-xl {
  padding: 40px !important; }

.xs-up-p-xl-child > * {
  padding: 40px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 40px !important; }

.xs-up-pt-xl {
  padding-top: 40px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 40px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 40px !important; }

.xs-up-pb-xl {
  padding-bottom: 40px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 40px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 40px !important; }

.xs-up-py-xl {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.xs-up-py-xl-child > * {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.xs-up-pl-xl {
  padding-left: 40px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 40px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 40px !important; }

.xs-up-pr-xl {
  padding-right: 40px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 40px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 40px !important; }

.xs-up-px-xl {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.xs-up-px-xl-child > * {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.xs-up-m-xxl {
  margin: 80px !important; }

.xs-up-m-xxl-child > * {
  margin: 80px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 80px !important; }

.xs-up-mt-xxl {
  margin-top: 80px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 80px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important; }

.xs-up-mb-xxl {
  margin-bottom: 80px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 80px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 80px !important; }

.xs-up-my-xxl {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.xs-up-ml-xxl {
  margin-left: 80px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 80px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important; }

.xs-up-mr-xxl {
  margin-right: 80px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 80px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 80px !important; }

.xs-up-mx-xxl {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.xs-up-p-xxl {
  padding: 80px !important; }

.xs-up-p-xxl-child > * {
  padding: 80px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 80px !important; }

.xs-up-pt-xxl {
  padding-top: 80px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 80px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important; }

.xs-up-pb-xxl {
  padding-bottom: 80px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 80px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 80px !important; }

.xs-up-py-xxl {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.xs-up-pl-xxl {
  padding-left: 80px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 80px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important; }

.xs-up-pr-xxl {
  padding-right: 80px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 80px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 80px !important; }

.xs-up-px-xxl {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 80px !important;
  padding-right: 80px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 20px !important; }
  .sm-up-m-md-child > * {
    margin: 20px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 20px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 20px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 20px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 20px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .sm-up-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 20px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 20px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 20px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 20px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 20px !important; }
  .sm-up-p-md-child > * {
    padding: 20px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 20px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 20px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 20px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 20px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .sm-up-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 20px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 20px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 20px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 20px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .sm-up-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 30px !important; }
  .sm-up-m-lg-child > * {
    margin: 30px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 30px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 30px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 30px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 30px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 30px !important; }
  .sm-up-p-lg-child > * {
    padding: 30px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 30px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 30px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 30px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 30px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 40px !important; }
  .sm-up-m-xl-child > * {
    margin: 40px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 40px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 40px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 40px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 40px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 40px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 40px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 40px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 40px !important; }
  .sm-up-p-xl-child > * {
    padding: 40px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 40px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 40px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 40px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 40px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 40px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 40px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 40px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 80px !important; }
  .sm-up-m-xxl-child > * {
    margin: 80px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 80px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 80px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 80px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 80px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 80px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 80px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 80px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 80px !important; }
  .sm-up-p-xxl-child > * {
    padding: 80px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 80px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 80px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 80px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 80px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 80px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 80px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 80px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 20px !important; }
  .md-up-m-md-child > * {
    margin: 20px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 20px !important; }
  .md-up-mt-md-child > * {
    margin-top: 20px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 20px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 20px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .md-up-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 20px !important; }
  .md-up-ml-md-child > * {
    margin-left: 20px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 20px !important; }
  .md-up-mr-md-child > * {
    margin-right: 20px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .md-up-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 20px !important; }
  .md-up-p-md-child > * {
    padding: 20px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 20px !important; }
  .md-up-pt-md-child > * {
    padding-top: 20px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 20px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 20px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .md-up-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 20px !important; }
  .md-up-pl-md-child > * {
    padding-left: 20px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 20px !important; }
  .md-up-pr-md-child > * {
    padding-right: 20px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .md-up-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 30px !important; }
  .md-up-m-lg-child > * {
    margin: 30px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 30px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 30px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 30px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 30px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 30px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 30px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 30px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 30px !important; }
  .md-up-p-lg-child > * {
    padding: 30px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 30px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 30px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 30px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 30px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 30px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 30px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 30px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 40px !important; }
  .md-up-m-xl-child > * {
    margin: 40px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 40px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 40px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 40px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .md-up-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 40px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 40px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 40px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 40px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 40px !important; }
  .md-up-p-xl-child > * {
    padding: 40px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 40px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 40px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 40px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .md-up-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 40px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 40px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 40px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 40px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .md-up-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 80px !important; }
  .md-up-m-xxl-child > * {
    margin: 80px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 80px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 80px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 80px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 80px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 80px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 80px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 80px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 80px !important; }
  .md-up-p-xxl-child > * {
    padding: 80px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 80px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 80px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 80px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 80px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 80px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 80px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 80px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 20px !important; }
  .lg-up-m-md-child > * {
    margin: 20px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 20px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 20px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 20px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 20px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .lg-up-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 20px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 20px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 20px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 20px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 20px !important; }
  .lg-up-p-md-child > * {
    padding: 20px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 20px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 20px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 20px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 20px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .lg-up-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 20px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 20px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 20px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 20px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .lg-up-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 30px !important; }
  .lg-up-m-lg-child > * {
    margin: 30px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 30px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 30px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 30px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 30px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 30px !important; }
  .lg-up-p-lg-child > * {
    padding: 30px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 30px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 30px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 30px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 30px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 40px !important; }
  .lg-up-m-xl-child > * {
    margin: 40px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 40px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 40px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 40px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 40px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 40px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 40px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 40px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 40px !important; }
  .lg-up-p-xl-child > * {
    padding: 40px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 40px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 40px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 40px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 40px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 40px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 40px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 40px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 80px !important; }
  .lg-up-m-xxl-child > * {
    margin: 80px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 80px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 80px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 80px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 80px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 80px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 80px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 80px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 80px !important; }
  .lg-up-p-xxl-child > * {
    padding: 80px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 80px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 80px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 80px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 80px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 80px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 80px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 80px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1440px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-md {
    margin: 20px !important; }
  .xl-up-m-md-child > * {
    margin: 20px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-md {
    margin-top: 20px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 20px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-md {
    margin-bottom: 20px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 20px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .xl-up-my-md-child > * {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-md {
    margin-left: 20px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 20px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-md {
    margin-right: 20px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 20px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 20px !important;
    margin-right: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-md {
    padding: 20px !important; }
  .xl-up-p-md-child > * {
    padding: 20px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-md {
    padding-top: 20px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 20px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-md {
    padding-bottom: 20px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 20px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .xl-up-py-md-child > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 20px !important;
    padding-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-md {
    padding-left: 20px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 20px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-md {
    padding-right: 20px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 20px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-md {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .xl-up-px-md-child > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-lg {
    margin: 30px !important; }
  .xl-up-m-lg-child > * {
    margin: 30px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-lg {
    margin-top: 30px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-lg {
    margin-bottom: 30px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-lg {
    margin-left: 30px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-lg {
    margin-right: 30px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-lg {
    padding: 30px !important; }
  .xl-up-p-lg-child > * {
    padding: 30px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-lg {
    padding-top: 30px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-lg {
    padding-bottom: 30px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-lg {
    padding-left: 30px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-lg {
    padding-right: 30px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-lg {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-xl {
    margin: 40px !important; }
  .xl-up-m-xl-child > * {
    margin: 40px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-xl {
    margin-top: 40px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 40px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-xl {
    margin-bottom: 40px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 40px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-xl {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 40px !important;
    margin-bottom: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-xl {
    margin-left: 40px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 40px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-xl {
    margin-right: 40px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 40px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-xl {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 40px !important;
    margin-right: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-xl {
    padding: 40px !important; }
  .xl-up-p-xl-child > * {
    padding: 40px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-xl {
    padding-top: 40px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 40px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-xl {
    padding-bottom: 40px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 40px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-xl {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-xl {
    padding-left: 40px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 40px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-xl {
    padding-right: 40px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 40px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-xl {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media (min-width: 1440px) {
  .xl-up-m-xxl {
    margin: 80px !important; }
  .xl-up-m-xxl-child > * {
    margin: 80px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-mt-xxl {
    margin-top: 80px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 80px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-mb-xxl {
    margin-bottom: 80px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 80px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-my-xxl {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 80px !important;
    margin-bottom: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-ml-xxl {
    margin-left: 80px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 80px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-mr-xxl {
    margin-right: 80px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 80px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-mx-xxl {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 80px !important;
    margin-right: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-p-xxl {
    padding: 80px !important; }
  .xl-up-p-xxl-child > * {
    padding: 80px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-pt-xxl {
    padding-top: 80px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 80px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-pb-xxl {
    padding-bottom: 80px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 80px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-py-xxl {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 80px !important;
    padding-bottom: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-pl-xxl {
    padding-left: 80px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 80px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-pr-xxl {
    padding-right: 80px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 80px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 80px !important; } }

@media (min-width: 1440px) {
  .xl-up-px-xxl {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: "content";
  src: url("../fonts/roboto/regular.eot");
  src: url("../fonts/roboto/regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/regular.woff2") format("woff2"), url("../fonts/roboto/regular.woff") format("woff"), url("../fonts/roboto/regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: "title";
  src: url("../fonts/jomolhari/title.eot");
  src: url("../fonts/jomolhari/title.eot?#iefix") format("embedded-opentype"), url("../fonts/jomolhari/title.woff2") format("woff2"), url("../fonts/jomolhari/title.woff") format("woff"), url("../fonts/jomolhari/title.ttf") format("truetype"), url("../fonts/jomolhari/title.svg#title") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-Book.eot");
  src: url("../fonts/caslon/Caslon224Std-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-Book.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-Book.woff") format("woff"), url("../fonts/caslon/Caslon224Std-Book.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-Book.svg#Caslon224Std-Book") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-BoldItalic.eot");
  src: url("../fonts/caslon/Caslon224Std-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-BoldItalic.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-BoldItalic.woff") format("woff"), url("../fonts/caslon/Caslon224Std-BoldItalic.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-BoldItalic.svg#Caslon224Std-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-Black.eot");
  src: url("../fonts/caslon/Caslon224Std-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-Black.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-Black.woff") format("woff"), url("../fonts/caslon/Caslon224Std-Black.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-Black.svg#Caslon224Std-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-BlackItalic.eot");
  src: url("../fonts/caslon/Caslon224Std-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-BlackItalic.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-BlackItalic.woff") format("woff"), url("../fonts/caslon/Caslon224Std-BlackItalic.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-BlackItalic.svg#Caslon224Std-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-MediumItalic.eot");
  src: url("../fonts/caslon/Caslon224Std-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-MediumItalic.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-MediumItalic.woff") format("woff"), url("../fonts/caslon/Caslon224Std-MediumItalic.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-MediumItalic.svg#Caslon224Std-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-Bold.eot");
  src: url("../fonts/caslon/Caslon224Std-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-Bold.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-Bold.woff") format("woff"), url("../fonts/caslon/Caslon224Std-Bold.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-Bold.svg#Caslon224Std-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-BookItalic.eot");
  src: url("../fonts/caslon/Caslon224Std-BookItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-BookItalic.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-BookItalic.woff") format("woff"), url("../fonts/caslon/Caslon224Std-BookItalic.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-BookItalic.svg#Caslon224Std-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "ITC Caslon 224 Std";
  src: url("../fonts/caslon/Caslon224Std-Medium.eot");
  src: url("../fonts/caslon/Caslon224Std-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/caslon/Caslon224Std-Medium.woff2") format("woff2"), url("../fonts/caslon/Caslon224Std-Medium.woff") format("woff"), url("../fonts/caslon/Caslon224Std-Medium.ttf") format("truetype"), url("../fonts/caslon/Caslon224Std-Medium.svg#Caslon224Std-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow Book";
  src: url("../fonts/gotham/GothamNarrow-Book.eot");
  src: url("../fonts/gotham/GothamNarrow-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-Book.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-Book.woff") format("woff"), url("../fonts/gotham/GothamNarrow-Book.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-Book.svg#GothamNarrow-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-Bold.eot");
  src: url("../fonts/gotham/GothamNarrow-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-Bold.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-Bold.woff") format("woff"), url("../fonts/gotham/GothamNarrow-Bold.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-Bold.svg#GothamNarrow-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-BoldItalic.eot");
  src: url("../fonts/gotham/GothamNarrow-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-BoldItalic.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-BoldItalic.woff") format("woff"), url("../fonts/gotham/GothamNarrow-BoldItalic.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-BoldItalic.svg#GothamNarrow-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow Book";
  src: url("../fonts/gotham/GothamNarrow-BookItalic.eot");
  src: url("../fonts/gotham/GothamNarrow-BookItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-BookItalic.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-BookItalic.woff") format("woff"), url("../fonts/gotham/GothamNarrow-BookItalic.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-BookItalic.svg#GothamNarrow-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-Light.eot");
  src: url("../fonts/gotham/GothamNarrow-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-Light.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-Light.woff") format("woff"), url("../fonts/gotham/GothamNarrow-Light.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-Light.svg#GothamNarrow-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-LightItalic.eot");
  src: url("../fonts/gotham/GothamNarrow-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-LightItalic.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-LightItalic.woff") format("woff"), url("../fonts/gotham/GothamNarrow-LightItalic.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-LightItalic.svg#GothamNarrow-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-Medium.eot");
  src: url("../fonts/gotham/GothamNarrow-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-Medium.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-Medium.woff") format("woff"), url("../fonts/gotham/GothamNarrow-Medium.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-Medium.svg#GothamNarrow-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gotham Narrow";
  src: url("../fonts/gotham/GothamNarrow-MediumItalic.eot");
  src: url("../fonts/gotham/GothamNarrow-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/GothamNarrow-MediumItalic.woff2") format("woff2"), url("../fonts/gotham/GothamNarrow-MediumItalic.woff") format("woff"), url("../fonts/gotham/GothamNarrow-MediumItalic.ttf") format("truetype"), url("../fonts/gotham/GothamNarrow-MediumItalic.svg#GothamNarrow-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

*::before,
*::after,
img {
  pointer-events: none; }

body {
  font-family: "Gotham Narrow Book", sans-serif;
  color: #1a3041;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  color: #d3b68c;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-weight: 400;
  line-height: 1.2; }

@media (max-width: 575.98px) {
  h1 {
    overflow: hidden;
    position: relative; } }

h1:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 15px;
  height: 1px;
  width: 365px;
  background: white; }
  @media (max-width: 575.98px) {
    h1:before {
      left: 0px; } }

button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a,
img,
span,
button {
  display: inline-block; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.dflex {
  display: flex;
  align-items: center;
  vertical-align: middle; }

.dflex-end {
  display: flex;
  align-items: end;
  vertical-align: middle; }

.got {
  font-weight: normal;
  font-style: normal;
  font-family: "Gotham Narrow", sans-serif; }

.h-50 {
  height: 50vh; }

.h460 {
  height: 460px; }
  @media (max-width: 991.98px) {
    .h460 {
      height: initial; } }

h2.title-xl, .cms h2 {
  font-style: italic; }

.fw-l {
  font-weight: 300; }

.fs-i {
  font-style: italic; }

.upp {
  text-transform: uppercase; }

.txt-l {
  text-align: left; }

.pt-0-ip {
  padding-top: 0 !important; }

.itc {
  font-family: "ITC Caslon 224 Std"; }

.img-wrapper {
  position: relative; }

/*
|--------------------
|     HELPERS
|--------------------
*/
@media (max-width: 991.98px) {
  .desktop-only {
    display: none !important; } }

@media (min-width: 992px) {
  .mobile-only {
    display: none !important; } }

.img-full {
  height: 480px; }

.c-c {
  column-count: 2; }
  @media (max-width: 991.98px) {
    .c-c {
      column-count: 1; } }

.z-up {
  z-index: 12; }

.t-0 {
  top: 0; }

.l-0 {
  left: 0; }

.jc-sb {
  justify-content: space-between; }

.jc-end {
  justify-content: flex-end; }
  @media (max-width: 991.98px) {
    .jc-end {
      justify-content: center; } }

.jc-c {
  justify-content: center; }

.ai-b {
  align-items: baseline; }

.ai-c {
  align-items: center; }

.fd-c {
  flex-direction: column; }

.tt-n {
  text-transform: none !important; }

@media (max-width: 1439.98px) {
  .lg-fd-c {
    flex-direction: column; } }

/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
.link-menu, .item-menu li .item-link, #header .lang-container {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400; }

.item-menu {
  display: flex; }
  .item-menu li {
    position: relative; }
    .item-menu li:not(.log-in):not(.log-out) {
      margin-right: 14px; }
    .item-menu li.icon-invest {
      padding-left: 24px; }
      .item-menu li.icon-invest:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
        background-image: url("../img/icon/login.svg");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center; }
    .item-menu li .item-link {
      font-size: 16px; }
    .item-menu li .nav-drop {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-left: -24px;
      padding: 30px 0px;
      transition: all ease 0.4s;
      opacity: 0;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      background: #1a3041;
      color: #ffffff;
      z-index: -1; }
      .item-menu li .nav-drop li {
        padding: 4px 40px;
        font-size: 20px;
        margin-right: 0px; }
    .item-menu li:hover {
      z-index: 20; }
      .item-menu li:hover .nav-drop {
        display: block;
        transition: all ease 0.4s;
        opacity: 1; }

#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #1a3041; }
  #header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #1a3041;
    height: 100%;
    width: 100%;
    z-index: 1;
    transform: translateY(-101%);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1); }
  #header .item-logo svg {
    height: 90px;
    transition: all 0.4s ease; }
    #header .item-logo svg path,
    #header .item-logo svg polygon,
    #header .item-logo svg circle,
    #header .item-logo svg text {
      transition: all 0.4s ease; }
  #header.is-scroll:before {
    transform: translateY(0); }
  #header.is-scroll:not(.is-open) .item-logo svg {
    fill: #ffffff; }
    #header.is-scroll:not(.is-open) .item-logo svg path,
    #header.is-scroll:not(.is-open) .item-logo svg polygon,
    #header.is-scroll:not(.is-open) .item-logo svg circle,
    #header.is-scroll:not(.is-open) .item-logo svg text {
      fill: #ffffff; }
  #header .header-container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 10;
    justify-content: space-between; }
    #header .header-container .item-logo img {
      height: 90px; }
    #header .header-container .item-nav {
      justify-content: flex-end;
      display: none; }
      @media (min-width: 1260px) {
        #header .header-container .item-nav {
          display: flex;
          align-items: center; } }
  #header .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    cursor: pointer; }
    #header .lang-container ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s cubic-bezier(0.19, 1, 0.22, 1), transform 2s cubic-bezier(0.19, 1, 0.22, 1); }
    #header .lang-container:hover ul {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateX(0) !important; }
  #header .social-container ul li a svg {
    height: 12px;
    width: auto;
    fill: #ffffff; }
    #header .social-container ul li a svg path,
    #header .social-container ul li a svg polygon,
    #header .social-container ul li a svg circle,
    #header .social-container ul li a svg text {
      fill: #ffffff; }
  #header .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer; }
    #header .btn-menu > .item-burger {
      display: block;
      width: 25px; }
      #header .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2) {
          margin: 6px 0; }
    @media (min-width: 1260px) {
      #header .btn-menu {
        display: none; } }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #1a3041;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12%;
  padding-bottom: 40px;
  color: #ffffff;
  opacity: 0;
  visibility: hidden; }
  #mobile-menu .item-link {
    color: #ffffff !important; }
  #mobile-menu .item-container {
    text-align: center; }
    #mobile-menu .item-container .mobile-item-menu li.menu-item-has-children .item-link {
      position: relative; }
      #mobile-menu .item-container .mobile-item-menu li.menu-item-has-children .item-link:after {
        content: "";
        background-image: url("../img/icon/arrow-white.svg");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        height: 14px;
        width: 24px;
        top: 16px;
        margin-left: 12px; }
    #mobile-menu .item-container .mobile-item-menu > li:not(:last-child) {
      margin-bottom: 12px; }
    #mobile-menu .item-container .mobile-item-menu > li a {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      -webkit-font-smoothing: antialiased; }
    #mobile-menu .item-container .mobile-item-menu .nav-drop {
      display: none;
      height: 0;
      transition: height 0.4s ease 0; }
      #mobile-menu .item-container .mobile-item-menu .nav-drop a {
        font-size: 16px; }
        @media (max-width: 575.98px) {
          #mobile-menu .item-container .mobile-item-menu .nav-drop a {
            font-size: 18px; } }
    #mobile-menu .item-container .mobile-item-menu .active .nav-drop {
      height: auto;
      display: block;
      transition: height 0.4s ease 0.4s; }
  @media (min-width: 768px) {
    #mobile-menu {
      left: inherit;
      right: 0;
      width: 50%;
      padding-right: 40px; }
      #mobile-menu .item-container {
        text-align: right; }
        #mobile-menu .item-container ul li.menu-item-has-children .item-link {
          padding-right: 40px; }
          #mobile-menu .item-container ul li.menu-item-has-children .item-link:after {
            top: 12px;
            right: 0;
            margin-left: 0px; } }
  @media (max-width: 1260px) {
    #mobile-menu {
      display: flex; } }

body .item-menu .log-in,
body .mobile-item-menu .log-in {
  display: block; }

body .item-menu .log-out,
body .item-menu .dashboard,
body .mobile-item-menu .log-out,
body .mobile-item-menu .dashboard {
  display: none; }

body.logged-in .item-menu .log-in,
body.logged-in .mobile-item-menu .log-in {
  display: none; }

body.logged-in .item-menu .log-out,
body.logged-in .item-menu .dashboard,
body.logged-in .mobile-item-menu .log-out,
body.logged-in .mobile-item-menu .dashboard {
  display: block; }

body.home .navy-home .item-link {
  color: #1a3041; }
  body.home .navy-home .item-link.btn-secondary:after {
    background: #1a3041 !important; }
  @media (max-width: 1260px) {
    body.home .navy-home .item-link {
      color: #ffffff; }
      body.home .navy-home .item-link.btn-secondary:after {
        background: #ffffff !important; } }

body.home #header .item-nav {
  position: relative;
  width: 1260px; }

body.home #header.is-scroll .navy-home .item-link {
  color: #ffffff; }
  body.home #header.is-scroll .navy-home .item-link.btn-secondary:after {
    background: #ffffff !important; }

@media (max-width: 767.98px) {
  body.home #header.is-scroll .btn-menu > .item-burger > span {
    background-color: #ffffff; } }

body:not(.home) #header .item-logo svg path,
body:not(.home) #header .item-logo svg polygon {
  fill: #ffffff; }

body #header.is-open:before {
  transform: translateY(-100%); }

@media (max-width: 767.98px) {
  body #header.is-open .btn-menu > .item-burger > span {
    background-color: #ffffff; } }

@media (max-width: 767.98px) {
  body #header.is-open .item-logo {
    opacity: 0; } }

.language-switcher {
  position: relative;
  padding-block: .5rem;
  text-transform: uppercase;
  line-height: 1.2;
  color: inherit; }
  .language-switcher-current {
    display: block;
    color: #FFF;
    cursor: default; }
  .language-switcher-list {
    position: absolute;
    top: 100%;
    left: -.75rem;
    padding: .5rem .75rem;
    background-color: #FFF;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path .25s ease; }
  .language-switcher:hover .language-switcher-list, .language-switcher-list:focus-within {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.mobile-language-switcher {
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  gap: 1.25rem;
  text-transform: uppercase; }
  .mobile-language-switcher-item:not(:last-child) {
    position: relative; }
    .mobile-language-switcher-item:not(:last-child)::after {
      display: block;
      width: 1.25rem;
      position: absolute;
      top: 0;
      left: 100%;
      text-align: center;
      content: '-'; }
  .mobile-language-switcher span {
    font-weight: 700;
    cursor: default; }

@media (max-width: 1260px) {
  body.logged-in .header-container .item-nav {
    display: none !important; }
  body.logged-in .header-container .btn-menu {
    display: inline-flex !important; }
  body.logged-in #mobile-menu {
    display: flex !important; } }

/*
|--------------------
|      FOOTER
|--------------------
*/
.item-footer {
  width: 80%; }
  .item-footer a,
  .item-footer span {
    color: #ffffff;
    font-weight: 400; }

.item-logo img {
  height: 90px; }

.credit-link {
  opacity: 0.5;
  transition: opacity 0.8s ease; }
  .credit-link span {
    text-transform: none;
    font-style: italic; }
  .credit-link:hover {
    opacity: 1; }

#ot-sdk-btn {
  border: none !important;
  color: white !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  padding: 0px !important;
  margin: 0px !important; }
  #ot-sdk-btn:hover {
    background: none !important; }

/*
|--------------------
|      CONTENT
|--------------------
*/
.t-c {
  text-align: center; }

.max-w-none {
  max-width: none; }

.ws-n {
  white-space: nowrap; }

.section {
  position: relative; }

.tt-u {
  text-transform: uppercase; }

.rel {
  position: relative; }

.f-white:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: #ffffff;
  opacity: 0.7;
  transition: opacity 0.4s ease; }

.post-card:hover .f-white:before {
  opacity: 0; }

.opacity-20 {
  opacity: 0.2; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.o-blue:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: #1a3041;
  opacity: 0.64; }

.section-map #map {
  position: relative;
  width: 100%;
  height: 80vh; }

.separator {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  z-index: 2;
  background: #1a3041; }
  .separator.is-sm {
    margin-top: -40px;
    height: 80px; }
  .separator.is-md {
    margin-top: -80px;
    height: 160px; }
  .separator.is-lg {
    margin-top: -200px;
    height: 400px; }
  .separator-alt {
    background: white; }

.bg-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #1a3041;
  opacity: 0.8; }

.svg-white svg {
  fill: #ffffff; }
  .svg-white svg path,
  .svg-white svg polygon,
  .svg-white svg circle,
  .svg-white svg text {
    fill: #ffffff; }

.back-btn-container .hr-container {
  height: 1px;
  width: 4%;
  background: #ffffff;
  left: 0;
  margin-top: 12px; }

.hr-l {
  position: relative;
  padding-left: 40px; }
  .hr-l:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: #1a3041; }

.hr-b {
  position: relative; }
  .hr-b:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100px;
    background: #ffffff; }

/*
|
| Cursor Follow
|--------------
*/
.cursor-container {
  position: absolute;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  z-index: 400;
  transition: margin 0.4s ease;
  background: #fff;
  border-radius: 50%;
  height: 100px;
  font-size: 14px;
  width: 100px;
  transform: scale(0.4);
  opacity: 0;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s ease;
  pointer-events: none; }
  .cursor-container .cursor-in {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s ease; }

body.is-cursor .cursor-container {
  transform: scale(1);
  opacity: 1; }
  body.is-cursor .cursor-container .cursor-in {
    opacity: 1; }

html.is-device .cursor-container {
  display: none; }

/*
|
| Banner
|---------
*/
.banner {
  position: relative;
  display: flex;
  align-items: end;
  width: 100%;
  padding: 120px 0 0 0;
  background-color: #1a3041; }
  .banner .item-content {
    width: 100%;
    padding: 60px 0; }
    @media (max-width: 991.98px) {
      .banner .item-content .cms {
        margin-top: 20px; } }
    .banner .item-content .cms {
      font-weight: 300;
      font-family: "Gotham Narrow"; }
  .banner:not(.banner-home) .img-wrapper {
    width: 100%;
    right: 0;
    left: inherit; }
  .banner.bg-before {
    background-color: rgba(0, 0, 0, 0); }
    .banner.bg-before:before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: #1a3041;
      height: 80vh;
      width: 100%; }

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  /*
    * Card img
    */
  /*
    * Card content
    */
  /*
    * Hover state
    */ }
  .custom-card .custom-card-link {
    display: flex;
    flex-direction: column; }
  .custom-card .card-img-container {
    position: relative;
    overflow: hidden; }
    .custom-card .card-img-container:after {
      content: "";
      display: block;
      padding-bottom: 70%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.01);
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
  .custom-card .card-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 240px; }
    .custom-card .card-content .btn-container {
      align-self: flex-start;
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
  .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 2000;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #5e6f7c; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #5e6f7c; }

/*
|
| CMS
|------
*/
.cms span {
  display: inline; }

.cms h1,
.cms h2,
.cms h3,
.cms h4,
.cms h5,
.cms h6 {
  margin-bottom: 30px; }

.cms img {
  display: block;
  width: 100%;
  height: auto; }

.cms strong {
  font-weight: 800; }

.cms p {
  margin-bottom: 2px; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  padding: 130px 0;
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 300; }
  @media (max-width: 991.98px) {
    .cms blockquote {
      margin: auto; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    background-image: url("../img/icon/quote-left.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(-60px) translateY(-30px); }
  .cms blockquote:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../img/icon/quote-right.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(0) translateY(-50px); }
    @media (max-width: 991.98px) {
      .cms blockquote:after {
        transform: translateX(60px) translateY(-50px); } }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 120px; }
  @media (max-width: 1439.98px) {
    .page-offset {
      padding-top: 120px; } }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: white; }
    .custom-loader svg circle {
      stroke: white; }
  .custom-loader.ajax-loader {
    display: none; }

.map-card {
  padding: 16px 16px; }

.page-template-funds h3 {
  position: relative; }
  .page-template-funds h3:before {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #fff;
    display: block;
    position: absolute;
    bottom: -4px; }

#page-news-archive .loaded-posts {
  color: white; }

.post-type-archive-projects #header,
.page-template-sustainability #header,
.page-template-funds #header,
.page-template-contact #header,
.page-template-dashboard #header,
.single #header,
.blog #header {
  background: transparent; }

.post-type-archive-projects .banner,
.banner-img {
  /*     &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: $navy;
        opacity: 0.7;
        transition: opacity 0.4s ease;
    } */ }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
#page-home {
  display: block; }
  #page-home .banner {
    /*
		.before-container {
			pointer-events: none;
			z-index: -1;

			.container {
				position: relative;

				.before-size {
					position: absolute;
					right: 0;
					top: 0;
					width: 1200px;

					&:before {
						content: "";
						position: absolute;
						height: 100%;
						margin-top: -170px;
						width: 59%;
						margin-right: -15%;
						right: 0;
						top: 0;
						background: $navy;
						z-index: -1;

						@include media-breakpoint-down(md) {
							width: 30%;
						}
					}
				}
			}

			// @media screen and (max-width: 1140px) {
			// 	width: 30%;
			// }
		}
*/ }
    #page-home .banner .item-content {
      margin-top: -120px;
      color: white; }
    #page-home .banner .img-container {
      color: white; }
      #page-home .banner .img-container a {
        color: white; }
    #page-home .banner .img-wrapper {
      /* 			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 100%;
				background: $white;
				opacity: 0.4;
				top: 0;
				left: 0;
			} */ }
  #page-home .section-about .img-split .img-container {
    margin-top: 0px; }
  #page-home .section-about:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #1a3041;
    width: 100%;
    height: 100%; }
  #page-home .mobile-cursor {
    pointer-events: none; }
    @media (max-width: 991.98px) {
      #page-home .mobile-cursor {
        pointer-events: initial; } }
  #page-home .split-container {
    display: flex;
    align-items: flex-end;
    min-height: 480px; }
    @media (max-width: 991.98px) {
      #page-home .split-container .split-link {
        height: 480px;
        display: flex;
        align-items: center;
        justify-content: center; } }
    #page-home .split-container .img-link {
      overflow: hidden; }
      #page-home .split-container .img-link .bg-cover, #page-home .split-container .img-link .bg-cover-top, #page-home .split-container .img-link .bg-cover-bottom, #page-home .split-container .img-link .banner {
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1); }
      #page-home .split-container .img-link:hover .bg-cover, #page-home .split-container .img-link:hover .bg-cover-top, #page-home .split-container .img-link:hover .bg-cover-bottom, #page-home .split-container .img-link:hover .banner {
        transform: scale(1.1); }
  #page-home .img-split {
    height: 400px; }
    @media (max-width: 1439.98px) {
      #page-home .img-split {
        height: 400px; } }
    @media (max-width: 991.98px) {
      #page-home .img-split {
        height: 480px; } }
    @media (max-width: 767.98px) {
      #page-home .img-split {
        height: 280px; }
        #page-home .img-split .img-container {
          min-height: 280px; } }
  #page-home .section.funds .swiper-container {
    height: 400px; }
    @media (max-width: 991.98px) {
      #page-home .section.funds .swiper-container {
        height: 480px; } }
  #page-home .section.funds .fund-item {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-align: center; }
    @media (min-width: 768px) {
      #page-home .section.funds .fund-item {
        align-items: flex-end;
        text-align: left; } }
    #page-home .section.funds .fund-item::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
      background: #1a3041;
      opacity: 0.64; }
    #page-home .section.funds .fund-item img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1); }
    #page-home .section.funds .fund-item:hover img {
      transform: scale(1.1); }
    #page-home .section.funds .fund-item-content {
      position: relative;
      z-index: 3;
      padding: 40px; }

.img-split {
  width: 50%;
  z-index: 20; }
  .img-split:not(.down-right) {
    top: 0; }
  .img-split.down-right {
    bottom: 0; }
  .img-split.col-l {
    left: 0; }
  .img-split.col-r {
    right: 0; }
  .img-split .img-container {
    position: relative;
    overflow: hidden; }
  @media (max-width: 991.98px) {
    .img-split {
      height: 480px; } }
  @media (max-width: 767.98px) {
    .img-split {
      height: 280px; }
      .img-split .img-container {
        min-height: 280px; } }

/* body {
	&.logged-in {
		#page-home {
			.banner {
				.before-container {
					.container {
						.before-size {
							&:before {
								width: 72%;
							}
						}
					}
				}
			}
		}
	}
	
} */
/*
|--------------------
|      About
|--------------------
*/
.img-about {
  position: relative;
  height: 600px;
  /* &:before {
		content: "";
		position: absolute;
		height: 60%;
		width: 1px;
		left: 50%;
		top: 0;
		z-index: 10;
		background: $navy;
	} */ }
  @media (max-width: 991.98px) {
    .img-about {
      height: 480px; } }
  @media (max-width: 767.98px) {
    .img-about {
      height: 320px; } }
  .img-about .img-split {
    height: 600px !important;
    width: 50%; }
    @media (max-width: 991.98px) {
      .img-about .img-split {
        height: 480px !important; } }
    @media (max-width: 767.98px) {
      .img-about .img-split {
        height: 320px !important; } }
    .img-about .img-split:first-child {
      height: 80%; }
      @media (max-width: 991.98px) {
        .img-about .img-split:first-child {
          height: 480px; } }
      @media (max-width: 767.98px) {
        .img-about .img-split:first-child {
          height: 320px; } }
    .img-about .img-split:nth-child(2) {
      /* margin-top: 30px; */ }

.project-section .img-split {
  height: 600px !important; }

/*
|--------------------
|      TEAM
|--------------------
*/
#page-team .card-content {
  flex: 1 1 120px; }
  #page-team .card-content .ln-link {
    margin-top: auto; }

#page-team .card-img-container {
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  #page-team .card-img-container:after {
    content: "";
    display: block;
    padding-bottom: 110%; }
  #page-team .card-img-container .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.main-img {
  margin-top: -16vh; }

.swiper-team .swiper-slide {
  cursor: pointer; }

.members-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 990; }
  .members-section:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #1a3041;
    width: 100%;
    height: 100%;
    opacity: 0.4; }
  .members-section .cross-container {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 40px;
    margin-right: 40px; }
    .members-section .cross-container .cross {
      height: 40px;
      width: 40px;
      cursor: pointer; }
      .members-section .cross-container .cross svg {
        fill: #1a3041;
        height: 24px;
        width: 24px; }
        .members-section .cross-container .cross svg path {
          fill: #1a3041; }
  .members-section .members-container {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
    background: #ffffff; }
    .members-section .members-container .members-wrapper .member-slide {
      display: none;
      height: auto; }
      .members-section .members-container .members-wrapper .member-slide.active-member {
        display: block; }
  .members-section.active {
    pointer-events: all;
    opacity: 1; }

/*
|--------------------
|      FUNDS
|--------------------
*/
.sep {
  height: 100%;
  width: 1px;
  background: #ffffff;
  margin-left: 20px; }

.separator-funds .separator {
  left: 0;
  margin-left: 0; }
  .separator-funds .separator.is-blend {
    mix-blend-mode: difference;
    background: #ffffff; }
    .separator-funds .separator.is-blend.is-md {
      margin-top: -200px;
      height: 260px; }

.section-assets .img-wrapper {
  opacity: 0.4; }
  .section-assets .img-wrapper img {
    position: absolute;
    max-width: 40%;
    max-height: 40%; }
    .section-assets .img-wrapper img:first-child {
      top: 0;
      right: 0;
      margin-right: 20%; }
    .section-assets .img-wrapper img:nth-child(2) {
      top: 50%;
      left: 0;
      margin-top: -5%; }
    .section-assets .img-wrapper img:last-child {
      bottom: 0;
      right: 0; }

.key-container {
  min-height: 280px; }
  .key-container .icon-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background: #5e6f7c;
    border-radius: 50%; }
    .key-container .icon-item svg path {
      fill: #ffffff; }

.bg-cov {
  background-position: center;
  background-size: cover; }

/*
|--------------------
|      NEWS
|--------------------
*/
#page-news-archive .bloc-filters {
  float: right;
  width: 410px; }
  @media (max-width: 991.98px) {
    #page-news-archive .bloc-filters {
      float: left;
      width: 100%; } }

.select-container {
  max-width: 600px;
  font-family: "ITC Caslon 224 Std", sans-serif; }

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%; }

.custom-select {
  display: flex;
  flex-direction: column;
  border: none; }

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 300;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  z-index: 1; }
  .custom-select__trigger.white {
    border-bottom: 1px solid #ffffff; }
  .custom-select__trigger.blue {
    border-bottom: 1px solid #1a3041; }

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 0;
  background: #1a3041;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2; }

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all; }

.custom-option {
  position: relative;
  display: block;
  padding: 0 16px 0 16px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s; }

.custom-option:hover {
  cursor: pointer;
  background-color: #5e6f7c; }

.custom-option.selected {
  color: #1a3041;
  background-color: #ffffff; }

.arrow {
  position: relative;
  height: 15px;
  width: 15px; }
  .arrow:before, .arrow:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.5s; }
  .arrow.blue:before, .arrow.blue:after {
    background-color: #1a3041; }
  .arrow.white:before, .arrow.white:after {
    background-color: #ffffff; }

.arrow::before {
  left: -5px;
  transform: rotate(-45deg); }

.arrow::after {
  left: 5px;
  transform: rotate(45deg); }

.open .arrow::before {
  left: -5px;
  transform: rotate(45deg); }

.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg); }

.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(94, 111, 124, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(26, 48, 65, 0.6);
  font-weight: 400; }

@media (min-width: 992px) {
  .single-ctn {
    padding-left: 7px;
    padding-right: 7px; } }

.single h1:before {
  content: none; }

/*
|--------------------
|       Contact
|--------------------
*/
.separator-col {
  position: relative; }
  .separator-col:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: #ffffff; }

.captcha-label label {
  display: none; }

/*
|--------------------
|       Project
|--------------------
*/
#page-projects-archive .batch-posts .post-card .card-img-container:after {
  padding-bottom: 70%; }

#page-projects-archive .batch-posts .post-card .card-content {
  position: relative;
  margin-top: 52px;
  /* &:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					margin-left: 40px;
					height: 80px;
					width: 1px;
					z-index: 10;
					margin-top: -80px;
					background: $navy;
				} */ }

.swiper-gallery {
  max-height: 70vh; }
  .swiper-gallery .img-inline {
    width: 100%;
    height: auto; }
  .swiper-gallery .swiper-wrapper {
    height: auto !important; }
  .swiper-gallery .swiper-slide {
    height: 70vh; }
    .swiper-gallery .swiper-slide .img-wrapper {
      width: 100%; }

.swiper-pagination {
  position: relative;
  bottom: 0; }
  .swiper-pagination hr {
    position: relative;
    width: 80px;
    margin-left: -8px;
    transform: rotate(-45deg); }
  .swiper-pagination h5:first-child {
    margin-top: -2px; }
  .swiper-pagination h5:last-child {
    margin-top: 2px;
    margin-left: 40px; }

.swiper-scrollbar {
  height: 2px;
  width: 100%;
  border-radius: 0; }
  .swiper-scrollbar .swiper-scrollbar-drag {
    height: 2px;
    border-radius: 0;
    background: #1a3041; }

.nav-arrows {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  width: 80px;
  height: 40px;
  z-index: 10;
  margin-top: -40px; }
  .nav-arrows .swiper-next,
  .nav-arrows .swiper-prev {
    cursor: pointer;
    outline: none;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .nav-arrows .swiper-prev {
    margin-right: 12px; }
    .nav-arrows .swiper-prev svg {
      transform: rotate(180deg); }

@media (max-width: 991.98px) {
  .bg-img-landscape-sm:after {
    padding-bottom: 100%; } }

/*
|--------------------
|      DASHBOARD PAGE
|--------------------
*/
#page-dashboard .name-login {
  display: none;
  font-style: italic; }
  @media (max-width: 1276px) {
    #page-dashboard .name-login {
      display: block; } }

#page-dashboard .name-login-alt {
  position: absolute;
  top: -55px;
  right: 0;
  text-align: right;
  color: white;
  text-transform: initial;
  display: inline-table;
  font-size: 20px;
  font-style: italic; }
  @media (max-width: 1276px) {
    #page-dashboard .name-login-alt {
      display: none; } }

#page-dashboard h1 {
  position: relative;
  display: inline-block; }
  #page-dashboard h1:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 365px;
    background: white; }
    @media (max-width: 767.98px) {
      #page-dashboard h1:before {
        width: initial; } }

.um-form .um-field-label label {
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-weight: normal !important; }

.um-form .um-field-area input,
.um-form .um .um-form input[type="password"] {
  color: #ffffff !important;
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #ffffff !important; }

.um-form .um-field-checkbox-option {
  color: #ffffff !important; }

.um-form .um-col-alt-b a {
  color: #ffffff !important;
  opacity: 1 !important;
  text-decoration: underline !important; }

.um-form .um-col-alt {
  display: flex;
  flex-direction: column;
  align-items: center; }

.um-form .um-center {
  position: relative;
  width: fit-content !important; }
  .um-form .um-center:before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    background: #ffffff;
    top: 50%;
    left: 0;
    z-index: 1; }

.um-form input[type="submit"] {
  padding: 20px 30px !important;
  text-transform: uppercase !important;
  border: 1px solid #ffffff !important;
  width: 280px !important;
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 0px !important;
  margin-left: 30px !important;
  text-align: center !important;
  position: relative !important;
  display: inline-block !important;
  cursor: pointer !important;
  height: auto !important;
  transition: all 0.4s ease !important; }
  .um-form input[type="submit"]:hover {
    background: #ffffff !important;
    color: #1a3041; }

.custom-options.is-scrollable {
  max-height: 400px;
  overflow: auto;
  width: 400px; }
  @media (max-width: 991.98px) {
    .custom-options.is-scrollable {
      width: auto; } }

.banner-dash {
  min-height: fit-content; }

.share-content {
  display: none;
  transition: opacity 0.4s ease; }
  .share-content.is-active {
    display: block; }
  .share-content .row-custom {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 24px);
    margin-left: -12px;
    justify-content: space-between; }
    .share-content .row-custom .col-custom {
      flex: 1 1 0px;
      margin: 12px;
      overflow: hidden; }
      @media (min-width: 992px) {
        .share-content .row-custom .col-custom:first-child .col-11 {
          padding-right: 0px;
          padding-left: 0px; } }
      .share-content .row-custom .col-custom .bg-navy {
        height: 280px;
        align-items: center; }
        @media (max-width: 991.98px) {
          .share-content .row-custom .col-custom .bg-navy {
            height: initial; } }
      .share-content .row-custom .col-custom .title-db {
        height: 67px;
        display: table-cell;
        vertical-align: bottom;
        position: relative; }
        .share-content .row-custom .col-custom .title-db:before {
          content: "";
          height: 1px;
          width: 100%;
          background-color: #fff;
          display: block;
          position: absolute;
          bottom: -7px; }
        .share-content .row-custom .col-custom .title-db h3 {
          vertical-align: bottom;
          position: absolute;
          bottom: -3px; }
          @media (max-width: 1439.98px) {
            .share-content .row-custom .col-custom .title-db h3 {
              font-size: 23px !important; } }
    @media (max-width: 991.98px) {
      .share-content .row-custom {
        justify-content: unset; }
        .share-content .row-custom .col-custom {
          flex: 0 0 20em; } }
    @media (max-width: 767.98px) {
      .share-content .row-custom {
        max-width: 540px; }
        .share-content .row-custom .col-custom {
          flex: 0 0 100%;
          padding-right: 24px; } }

.page-template-dashboard .sep {
  margin-left: 15px; }

@media (max-width: 991.98px) {
  .page-template-dashboard .col-1-desk {
    display: none; } }

.report-single {
  display: none;
  transition: opacity 0.4s ease; }
  .report-single .img-container {
    height: 300px;
    display: flex; }
  .report-single.is-active {
    display: block; }

.report-select__trigger {
  padding: 0px !important;
  white-space: nowrap; }

.launch-date-wrapper {
  background-color: #1a3041;
  transform: translateY(-100%); }

@media (max-width: 991.98px) {
  .report-option {
    font-size: 12px; } }

.bloc-filters {
  float: left; }
  @media (max-width: 991.98px) {
    .bloc-filters {
      float: initial;
      min-width: initial; } }
  .bloc-filters .custom-select__trigger {
    padding: 0; }
    .bloc-filters .custom-select__trigger span {
      padding-right: 20px;
      color: white; }

/*
|--------------------
|      ESG
|--------------------
*/
#page-esg h1 {
  position: relative; }
  #page-esg h1:before {
    content: "";
    left: 0;
    width: 188px; }

#page-esg .img-esg {
  position: relative;
  height: 600px; }

#page-esg .icon-container {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #dfe2e5; }
  #page-esg .icon-container svg path {
    fill: #1a3041; }

.img-half {
  top: 0;
  width: 50%; }

@media (max-width: 1439.98px) {
  .img-margin-up {
    margin-top: -40px; } }

.col-r {
  right: 0; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-primary {
  padding: 20px 30px;
  text-transform: uppercase;
  border: 1px solid #1a3041;
  min-width: 280px;
  margin-left: 30px;
  font-weight: 300;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer; }
  .btn-primary:before {
    content: "";
    position: absolute;
    width: 60px;
    height: 1px;
    background: #1a3041;
    top: 50%;
    left: 0;
    margin-left: -30px;
    z-index: 1; }
  .btn-primary:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #1a3041;
    top: 0;
    left: 0;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1); }
  .btn-primary span {
    position: relative;
    letter-spacing: 0.2em;
    z-index: 10;
    color: #1a3041;
    padding-left: 12px;
    transition: color 0.4s ease; }
  .btn-primary:hover:after {
    transform: scaleX(1);
    transform-origin: left; }
  .btn-primary:hover span {
    color: #ffffff; }
  .btn-primary-alt {
    border: 1px solid #ffffff; }
    .btn-primary-alt:before, .btn-primary-alt:after {
      content: "";
      background: #ffffff; }
    .btn-primary-alt span {
      color: #ffffff; }
    .btn-primary-alt:hover span {
      color: #1a3041; }

.btn-secondary {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  transition: color 0.4s ease; }
  .btn-secondary:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #1a3041;
    bottom: 0;
    margin-bottom: -1px;
    left: 0;
    z-index: 1;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1); }
  .btn-secondary.c-white {
    color: #ffffff; }
    .btn-secondary.c-white:after {
      background: #ffffff; }
  .btn-secondary.btn-reverse:after {
    transform: scaleX(0);
    transform-origin: right; }
  .btn-secondary:hover:after {
    transform: scaleX(0);
    transform-origin: right; }
  .btn-secondary:hover.btn-reverse:after {
    transform: scaleX(1);
    transform-origin: left; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  -webkit-appearance: none;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

select {
  background-image: url("../img/icon/bottom-white.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-color: rgba(0, 0, 0, 0);
  background-position: calc(100% - 10px) center;
  border: none;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-family: "ITC Caslon 224 Std", sans-serif;
  font-size: 22px;
  width: max-content;
  padding-right: 40px; }
  @media (max-width: 991.98px) {
    select {
      width: auto; } }

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap .gform_fields input, .gravity-form-bootstrap .gform_fields textarea {
  width: 100%;
  background-color: transparent; }

/*
|
| Materialize form 
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px; }
  .materialize-form .materialize-form-group input {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px #ffffff solid;
    color: #ffffff;
    height: 25px; }
  .materialize-form .materialize-form-group.gfield_error label {
    top: -20px; }

/*
|
| Contact form 
|---------------
*/
.contact-form li {
  margin-bottom: 40px;
  width: 80%; }

.contact-form label {
  position: absolute;
  color: #ffffff;
  margin-top: -12px;
  text-transform: uppercase;
  letter-spacing: .1em; }

.contact-form textarea {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px #ffffff solid;
  color: #ffffff;
  height: 120px;
  width: 100%;
  margin-bottom: 24px;
  text-transform: uppercase;
  letter-spacing: .1em; }
  .contact-form textarea.placeholder_disabled {
    color: #ffffff; }

.contact-form input {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px #ffffff solid;
  color: #ffffff;
  height: 24px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: .1em; }

.contact-form input[type=checkbox] {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px !important;
  height: 16px;
  border: 1px solid #ffffff;
  margin-right: 10px;
  margin-bottom: 0;
  transition: .3s border; }
  .contact-form input[type=checkbox]:checked {
    border: 5px solid #ffffff; }

.contact-form #field_1_6 .gfield_label {
  display: none; }

.contact-form #field_1_6 label {
  margin-top: 0px;
  margin-left: 12px; }

.contact-form .gform_body {
  margin-bottom: 20px; }

.contact-form .gform_footer {
  position: relative;
  margin-left: -30px; }
  .contact-form .gform_footer:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: #ffffff;
    top: 50%;
    left: 0;
    z-index: 1; }

.contact-form input[type="submit"] {
  padding: 20px 30px;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  width: 280px;
  margin-left: 30px;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: auto !important;
  transition: all .4s ease; }
  .contact-form input[type="submit"]:hover {
    background: #ffffff;
    color: #1a3041; }

.contact-form .gform_confirmation_message {
  color: #ffffff; }

.contact-form .validation_message {
  margin-top: 5px;
  color: #BB0B0B;
  font-size: 14px; }

.validation_error {
  margin-bottom: 30px;
  color: #BB0B0B !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #1a3041;
    text-align: center; }
    .page-loader .item-content img {
      height: 140px; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #1a3041;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

.dispatcher {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto; }
  .dispatcher:not([data-opened=true]) {
    display: none; }
  .dispatcher-inner {
    display: grid;
    margin: auto;
    background-color: #FFF;
    color: #1F2F3F; }
  .dispatcher-image {
    position: relative; }
    .dispatcher-image img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      object-fit: cover; }
  .dispatcher-form {
    padding: clamp(2rem, 6vw, 90px) clamp(1rem, 5vw, 80px); }
  .dispatcher-title {
    font-family: "ITC Caslon 224 Std", sans-serif;
    font-size: clamp(1.75rem, 3.5vw, 2.5rem);
    line-height: 1.2; }
  .dispatcher-item {
    margin-top: 1.5rem; }
  .dispatcher-select {
    display: block;
    width: 100%;
    padding-block: 1rem;
    padding-right: 1.5rem;
    border-bottom: 1px solid;
    background-image: url(../img/icon/dispatcher-select-arrow.svg);
    background-repeat: no-repeat;
    background-size: 1.125rem auto;
    background-position: center right;
    font-size: 1.125rem;
    line-height: 1.2;
    color: inherit; }
  .dispatcher-error {
    margin-top: .25rem;
    font-size: .875rem;
    color: #F00; }
  .dispatcher-footer {
    position: relative;
    padding-right: 3rem;
    margin-top: 3rem; }
    [data-loading=true] .dispatcher-footer::after {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      inset: 0 0 0 auto;
      margin-block: auto;
      background-image: url(../img/svg-loaders/puff-black.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      content: ''; }
  .dispatcher-submit {
    margin-left: 0; }
  @media (min-width: 768px) {
    .dispatcher {
      padding: clamp(1rem, 5vw, 80px); }
      .dispatcher-inner {
        grid-template-columns: 1fr 2fr; } }
  @media (max-width: 991.98px) {
    .dispatcher-inner {
      min-height: 100%;
      grid-template-columns: 100%; }
    .dispatcher-image {
      aspect-ratio: 16/9; } }
