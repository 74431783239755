/*
|--------------------
|      FUNDS
|--------------------
*/
.sep {
	height: 100%;
	width: 1px;
	background: $white;
	margin-left: 20px;
}

.separator-funds {
	.separator {
		left: 0;
		margin-left: 0;

		&.is-blend {
			mix-blend-mode: difference;
			background: $white;

			&.is-md {
				margin-top: -200px;
				height: 260px;
			}
		}
	}
}

.section-assets {
	.img-wrapper {
		opacity: 0.4;

		img {
			position: absolute;
			max-width: 40%;
			max-height: 40%;

			&:first-child {
				top: 0;
				right: 0;
				margin-right: 20%;
			}

			&:nth-child(2) {
				top: 50%;
				left: 0;
				margin-top: -5%;
			}

			&:last-child {
				bottom: 0;
				right: 0;
			}
		}
	}
}

.key-container {
	min-height: 280px;
	// height: 280px;

	.icon-item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 50px;
		background: #5e6f7c;
		border-radius: 50%;

		svg {
			path {
				fill: $white;
			}
		}
	}
}

.bg-cov {
	background-position: center;
	background-size: cover;
}
