/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $white;
  font-size: 14px;
  font-weight: 400;
}

.item-menu {
  display: flex;

  li {
    position: relative;

    &:not(.log-in):not(.log-out) {
      margin-right: 14px;
    }

    &.icon-invest {
      padding-left: 24px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
        background-image: url("../img/icon/login.svg");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }

    .item-link {
      @extend .link-menu;
      font-size: 16px;
    }
    .nav-drop {
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      margin-top: 30px;
      margin-left: -24px;
      padding: 30px 0px;
      transition: all ease 0.4s;
      opacity: 0;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      background: $navy;
      color: $white;
      z-index: -1;

      li {
        padding: 4px 40px;
        font-size: 20px;
        margin-right: 0px;
      }
    }
    &:hover {
      z-index: 20;

      .nav-drop {
        display: block;
        transition: all ease 0.4s;
        opacity: 1;
      }
    }
  }
}

#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $navy;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: $navy;
    height: 100%;
    width: 100%;
    z-index: 1;
    transform: translateY(-101%);
    transition: transform 1.2s $easeSmooth;
  }

  .item-logo {
    svg {
      height: 90px;
      transition: all 0.4s ease;

      path,
      polygon,
      circle,
      text {
        transition: all 0.4s ease;
      }
    }
  }

  &.is-scroll {
    &:before {
      transform: translateY(0);
    }

    &:not(.is-open) {
      .item-logo {
        svg {
          fill: $white;

          path,
          polygon,
          circle,
          text {
            fill: $white;
          }
        }
      }
    }
  }

  .header-container {
    position: relative;
    @extend .container;
    display: flex;
    align-items: center;
    z-index: 10;
    justify-content: space-between;
    .item-logo {
      img {
        height: 90px;
      }
    }
    .item-nav {
      justify-content: flex-end;
      display: none;

      @media (min-width: 1260px) {
        display: flex;
        align-items: center;
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .social-container {
    ul {
      li {
        a {
          svg {
            height: 12px;
            width: auto;
            fill: $white;

            path,
            polygon,
            circle,
            text {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @media (min-width: 1260px) {
      display: none;
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $navy;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12%;
  padding-bottom: 40px;
  color: $white;
  opacity: 0;
  visibility: hidden;

  .item-link {
    color: $white !important;
  }
  .item-container {
    text-align: center;
    @extend .container;

    .mobile-item-menu {
      li {
        &.menu-item-has-children {
          .item-link {
            position: relative;
            &:after {
              content: "";
              background-image: url("../img/icon/arrow-white.svg");
              background-size: cover;
              background-repeat: no-repeat;
              position: absolute;
              height: 14px;
              width: 24px;
              // right: 0;
              top: 16px;
              margin-left: 12px;
            }
          }
        }
      }

      & > li {
        &:not(:last-child) {
          margin-bottom: 12px;
        }

        a {
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          // @include media-breakpoint-down(xs) {
          //   font-size: 28px;
          // }
        }
      }

      .nav-drop {
        display: none;
        height: 0;
        transition: height 0.4s ease 0;

        a {
          font-size: 16px;

          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }
        }
      }

      .active {
        .nav-drop {
          height: auto;
          display: block;
          transition: height 0.4s ease 0.4s;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    left: inherit;
    right: 0;
    width: 50%;
    padding-right: 40px;

    .item-container {
      text-align: right;

      ul {
        li {
          &.menu-item-has-children {
            .item-link {
              padding-right: 40px;

              &:after {
                top: 12px;
                right: 0;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1260px) {
    display: flex;
  }
}

body {
  .item-menu,
  .mobile-item-menu {
    .log-in {
      display: block;
    }

    .log-out,
    .dashboard {
      display: none;
    }
  }

  &.logged-in {
    .item-menu,
    .mobile-item-menu {
      .log-in {
        display: none;
      }

      .log-out,
      .dashboard {
        display: block;
      }
    }
  }

  &.home {
    .navy-home {
      .item-link {
        color: $navy;

        &.btn-secondary {
          &:after {
            background: $navy !important;
          }
        }

        @media (max-width: 1260px) {
          color: $white;

          &.btn-secondary {
            &:after {
              background: $white !important;
            }
          }
        }
      }
    }

    #header {
      .item-nav {
        position: relative;
        width: 1260px;
      }

      &.is-scroll {
        .navy-home {
          .item-link {
            color: $white;

            &.btn-secondary {
              &:after {
                background: $white !important;
              }
            }
          }
        }
      }

      &.is-scroll {
        .btn-menu {
          & > .item-burger {
            & > span {
              @include media-breakpoint-down(sm) {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }

  &:not(.home) {
    #header {
      .item-logo {
        svg {
          path,
          polygon {
            fill: $white;
          }
        }
      }
    }
  }

  #header {
    &.is-open {
      &:before {
        transform: translateY(-100%);
      }

      .btn-menu {
        & > .item-burger {
          & > span {
            @include media-breakpoint-down(sm) {
              background-color: $white;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .item-logo {
          opacity: 0;
        }
      }
    }
  }
}

.language-switcher {
  position: relative;

  padding-block: .5rem;

  text-transform: uppercase;
  line-height: 1.2;
  color: inherit;

  &-current {
    display: block;

    color: #FFF;

    cursor: default;
  }

  &-list {
    position: absolute;
    top: 100%;
    left: -.75rem;

    padding: .5rem .75rem;

    background-color: #FFF;

    clip-path: polygon(
      0 0,
      100% 0,
      100% 0,
      0 0
    );
    transition: clip-path .25s ease;
  }

  &:hover &-list,
  &-list:focus-within {
    clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        0 100%
      );
  }
}

.mobile-language-switcher {
  display: flex;

  margin-top: 1.5rem;

  justify-content: center;
  gap: 1.25rem;

  text-transform: uppercase;

  &-item {
    &:not(:last-child) {
      position: relative;

      &::after {
        display: block;
        width: 1.25rem;

        position: absolute;
        top: 0;
        left: 100%;

        text-align: center;
        content: '-';
      }
    }
  }

  span {
    font-weight: 700;

    cursor: default;
  }
}

body {
  &.logged-in {
    @media (max-width: 1260px) {
      .header-container {
        .item-nav {
          display: none !important;
        }

        .btn-menu {
          display: inline-flex !important;
        }
      }

      #mobile-menu {
        display: flex !important;
      }
    }
  }
}
