/*
|--------------------
|       Project
|--------------------
*/
#page-projects-archive {
	.batch-posts {
		.post-card {
			.card-img-container {
				&:after {
					padding-bottom: 70%;
				}
			}

			.card-content {
				position: relative;
				margin-top: 52px;

				/* &:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					margin-left: 40px;
					height: 80px;
					width: 1px;
					z-index: 10;
					margin-top: -80px;
					background: $navy;
				} */
			}
		}
	}
}

.swiper-gallery {
	max-height: 70vh;

	.img-inline {
		width: 100%;
		height: auto;
	}

	.swiper-wrapper {
		height: auto !important;
		// margin-left: -10%;
	}

	.swiper-slide {
		height: 70vh;

		.img-wrapper {
			width: 100%;
		}
	}
}

.swiper-pagination {
	position: relative;
	bottom: 0;

	hr {
		position: relative;
		width: 80px;
		margin-left: -8px;
		transform: rotate(-45deg);
	}

	h5 {
		&:first-child {
			margin-top: -2px;
		}

		&:last-child {
			margin-top: 2px;
			margin-left: 40px;
		}
	}
}

.swiper-scrollbar {
	height: 2px;
	width: 100%;
	border-radius: 0;

	.swiper-scrollbar-drag {
		height: 2px;
		border-radius: 0;
		background: $navy;
	}
}

.nav-arrows {
	position: absolute;
	display: flex;
	right: 0;
	top: 0;
	width: 80px;
	height: 40px;
	z-index: 10;
	// margin-right: 10%;
	margin-top: -40px;

	.swiper-next,
	.swiper-prev {
		cursor: pointer;
		outline: none;
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.swiper-prev {
		margin-right: 12px;

		svg {
			transform: rotate(180deg);
		}
	}
}

.bg-img-landscape-sm {
	@include media-breakpoint-down(md) {
		&:after {
			padding-bottom: 100%;
		}
	}
}
