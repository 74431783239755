/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn-primary {
	padding: 20px 30px;
	text-transform: uppercase;
	border: 1px solid $navy;
	min-width: 280px;
	margin-left: 30px;
	font-weight: 300;
	text-align: center;
	position: relative;
	display: inline-block;
	cursor: pointer;

	&:before {
		content: "";
		position: absolute;
		width: 60px;
		height: 1px;
		background: $navy;
		top: 50%;
		left: 0;
		margin-left: -30px;
		z-index: 1;
	}

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: $navy;
		top: 0;
		left: 0;
		z-index: 1;
		transform: scaleX(0);
		transform-origin: right;
		transition: transform 1.2s $easeSmooth;
	}

	span {
		position: relative;
		letter-spacing: 0.2em;
		z-index: 10;
		color: $navy;
		padding-left: 12px;
		transition: color 0.4s ease;
	}

	&:hover {
		&:after {
			transform: scaleX(1);
			transform-origin: left;
		}

		span {
			color: $white;
		}
	}
	&-alt {
		border: 1px solid $white;
		&:before,
		&:after {
			content: "";
			background: $white;
		}
		span {
			color: $white;
		}
		&:hover {
			span {
				color: $navy;
			}
		}
	}
}

.btn-secondary {
	text-transform: uppercase;
	position: relative;
	display: inline-block;
	width: fit-content;
	cursor: pointer;
	transition: color 0.4s ease;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		background: $navy;
		bottom: 0;
		margin-bottom: -1px;
		left: 0;
		z-index: 1;
		transform: scaleX(1);
		transform-origin: left;
		transition: transform 1.2s $easeSmooth;
	}

	&.c-white {
		color: $white;

		&:after {
			background: $white;
		}
	}

	&.btn-reverse {
		&:after {
			transform: scaleX(0);
			transform-origin: right;
		}
	}

	&:hover {
		&:after {
			transform: scaleX(0);
			transform-origin: right;
		}

		&.btn-reverse {
			&:after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
	}
}
