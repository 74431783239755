/*
|--------------------
|      About
|--------------------
*/
.img-about {
	position: relative;
	height: 600px;

	@include media-breakpoint-down(md) {
		height: 480px;
	}

	@include media-breakpoint-down(sm) {
		height: 320px;
	}

	/* &:before {
		content: "";
		position: absolute;
		height: 60%;
		width: 1px;
		left: 50%;
		top: 0;
		z-index: 10;
		background: $navy;
	} */

	.img-split {
		height: 600px !important;
		width: 50%;

		@include media-breakpoint-down(md) {
			height: 480px !important;
		}

		@include media-breakpoint-down(sm) {
			height: 320px !important;
		}

		&:first-child {
			height: 80%;

			@include media-breakpoint-down(md) {
				height: 480px;
			}

			@include media-breakpoint-down(sm) {
				height: 320px;
			}
		}

		&:nth-child(2) {
			/* margin-top: 30px; */
		}
	}
}

.project-section {
	.img-split {
		height: 600px !important;
		// margin-top: -80px;
	}
}

/*
|--------------------
|      TEAM
|--------------------
*/

#page-team {
	.card-content {
		flex: 1 1 120px;

		.ln-link {
			margin-top: auto;
		}
	}

	.card-img-container {
		position: relative;
		overflow: hidden;
		cursor: pointer;

		&:after {
			content: "";
			display: block;
			padding-bottom: 110%;
		}

		.card-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.main-img {
	margin-top: -16vh;
}

.swiper-team {
	.swiper-slide {
		cursor: pointer;
	}
}

.members-section {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.4s ease;
	z-index: 990;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background: $navy;
		width: 100%;
		height: 100%;
		opacity: 0.4;
	}

	.cross-container {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 40px;
		margin-right: 40px;

		.cross {
			height: 40px;
			width: 40px;
			cursor: pointer;

			svg {
				fill: $navy;
				height: 24px;
				width: 24px;

				path {
					fill: $navy;
				}
			}
		}
	}

	.members-container {
		position: relative;
		width: 100%;
		height: 90vh;
		overflow-y: scroll;
		background: $white;

		.members-wrapper {
			.member-slide {
				display: none;
				height: auto;

				&.active-member {
					display: block;
				}
			}
		}
	}

	&.active {
		pointer-events: all;
		opacity: 1;
	}
}
